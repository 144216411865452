import { createSlice } from '@reduxjs/toolkit'
import { House } from '../../models/House'
import { UnavailableAppointment } from '../../models/UnavailableAppointment'
import { isEqualDates } from '../../assets/helpers/conpare-dates'

export const houseSlice = createSlice({
    name: 'house',
    initialState: {
        allHouses: [] as House[],
        loading: false,
        message: '',
        error: false
    },
    reducers: {
        startLoadingHouseSession: (state) => {
            return { ...state, loading: true }
        },
        endLoadingHouseSession: (state, action) => {
            return { ...state, loading: false, message: action.payload }
        },
        setAllHouses: (state, action) => {
            return { ...state, allHouses: action.payload }
        },
        addUnavailableAppointments: (state, action) => {
            var houses = JSON.parse(JSON.stringify(state.allHouses))
            houses.forEach((house: House) => {
                action.payload.unavailableAppointments.forEach((unavailableAppointment: UnavailableAppointment) => {
                    if (house.id === unavailableAppointment.houseId) {
                        if (house.unavailableAppointments !== null) {
                            house.unavailableAppointments.push(unavailableAppointment)
                        }
                        else {
                            house.unavailableAppointments = []
                            house.unavailableAppointments.push(unavailableAppointment)
                        }
                    }
                });
            })
            return { ...state, allHouses: houses }
        },
        addUnavailableAppointmentsForAllHouses: (state, action) => {
            
        },
        addMonthsWithUnavailableAppointments: (state, action) => {
            var houses = JSON.parse(JSON.stringify(state.allHouses))
            houses.forEach((house: House) => {
                if (house.id === action.payload.houseId) {
                    house.monthsWithUnavailableAppointments = action.payload.monthsWithUnavailableAppointments
                }
            })
            return { ...state, allHouses: houses }
        },
        clearHouseSession: (state) => {
            return { ...state, allHouses: [], loading: false, message: '', error: false }
        },
    },
})

export const {addMonthsWithUnavailableAppointments, startLoadingHouseSession, endLoadingHouseSession, setAllHouses, addUnavailableAppointments, addUnavailableAppointmentsForAllHouses, clearHouseSession } = houseSlice.actions

export default houseSlice.reducer