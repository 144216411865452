import axios from 'axios'
import { UnavailableAppointmentRouts } from "../routes";
import { Config } from "../config";

export const getUnavailableAppointments = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${UnavailableAppointmentRouts.GET_UNAVAILABLE_APPOINTMENTS}`, data)
    } catch (error) {
        throw error
    }
}

export const getUnavailableAppointmentsForAllHouses = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${UnavailableAppointmentRouts.GET_UNAVAILABLE_APPOINTMENTS_FOR_AL_HOUSES}`, data)
    } catch (error) {
        throw error
    }
}