
import { Grid, Typography } from "@mui/material";
//import { useSelector } from "react-redux";
import './style.css'
import {useNavigate} from 'react-router-dom'
import PrimaryButton from "../../components/bottons/primary-button";



function ErrorPage(){

    const navigate = useNavigate()
    //const houseSession = useSelector((state:any) => state.houseSession)

    return(
        <Grid className="error-bg" container sx={{height: "100vh", justifyContent: "center", alignItems: "center"}}>
            <Grid item sx={{textAlign: "center"}}>
                <Typography variant="h1" sx={{color: "white", fontWeight: "bold"}}>500</Typography>
                <Typography variant="h5" sx={{color: "white", fontWeight: "bold"}}>Internal server error.</Typography>
                <br/>
                <Typography variant="body1" sx={{color: "white"}}>Ooops, something went wrong.</Typography>
                <br/>
                <Typography variant="body1" sx={{color: "white"}}>Try to refresh this page or feel free to contact us if this problem persists.</Typography>
                <br/>
                <PrimaryButton onClick={()=>{navigate('/'); window.location.reload()}}>Refresh</PrimaryButton>
            </Grid>
        </Grid>
    )
}

export default ErrorPage;