import { Grid } from "@mui/material";
import PrimaryButton from "../bottons/primary-button";
import { useDispatch } from "react-redux";
import Actions from "../../store/actions";
import Api from "../../api";
import { useState } from "react";

interface Props {
    callback: Function
}

export default function AmenityCreator({ callback }: Props) {

    const dispatch = useDispatch()
    const [name, setName] = useState<string>("")

    const createAmenity = async () => {
        if (name !== "") {
            dispatch(Actions.startLoadingAmenitySession())
            await Api.addAmenity({ id: 0 })
                .then(response => {
                    console.log(response)
                    setName("")
                    createAmenityTranslation(response.data.id)
                    //dispatch(Actions.endLoadingContentSession(""))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(Actions.endLoadingAmenitySession("ERROR"))
                })
        }
    }

    const createAmenityTranslation = async (amenityId: number) => {
        await Api.addAmenityTranslation({ 
            id: 0,
            name: name, 
            amenityId: amenityId, 
            languageId: 1,
            amenity: null,
            language: null
        })
            .then(response => {
                callback()
                dispatch(Actions.endLoadingAmenitySession(""))
            })
            .catch(err => {
                console.log(err)
                dispatch(Actions.endLoadingAmenitySession("ERROR"))
            })
    }
    return (
        <Grid container xs={11} sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
            <Grid item xs={12} md={6}>
                <input type="text" value={name} onChange={(e) => { setName(e.target.value) }} className="admin-input" />
            </Grid>
            <Grid item>
                <PrimaryButton onClick={createAmenity}>Create new amenity</PrimaryButton>
            </Grid>
        </Grid>
    )
}