import { Grid, Typography } from "@mui/material";


function Success(){
    return(
        <Grid container sx={{height: "100vh", alignItems: "center", justifyContent: "center"}}>
            <Typography variant="h2">Success</Typography>
        </Grid>
    )
}

export default Success;