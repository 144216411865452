import { useState } from 'react'
import { useDispatch } from "react-redux"
import { Amenity } from "../../../models/Amenity"
import Actions from "../../../store/actions"
import Api from "../../../api"
import { AmenityByHouse } from '../../../models/AmenityByHouse'

interface Props {
    data: Amenity,
    setAmenities?: Function,
    selected: boolean,
    houseId: number,
    amenityByHouse?: AmenityByHouse,
    callback?: Function
}

export default function AmenityCard({ data, setAmenities, selected, houseId, amenityByHouse, callback }: Props) {

    const dispatch = useDispatch()

    const [amenityByHouseId, setAmenityByHouseId] = useState<number | null>(amenityByHouse !== undefined ? amenityByHouse.id : null)

    const createAmenityByHouse = async () => {
        dispatch(Actions.startLoadingAmenitySession())
        await Api.addAmenityByHouse({ amenityId: data.id, houseId: houseId })
            .then(response => {
                console.log(response)
                setAmenityByHouseId(response.data.id)
                setAmenities !== undefined && setAmenities((prev: Amenity[]) => [...prev, data])
                callback !== undefined && callback()
                dispatch(Actions.endLoadingAmenitySession(""))
            })
            .catch(err => {
                console.log(err)
                dispatch(Actions.endLoadingAmenitySession("ERROR"))
            })
    }

    const deleteAmenityByHouse = async () => {
        if (amenityByHouseId !== null) {
            dispatch(Actions.startLoadingAmenitySession())
            await Api.deleteAmenityByHouse(amenityByHouseId)
                .then(response => {
                    console.log(response)
                    setAmenities !== undefined && setAmenities((prev: Amenity[]) => [...prev.filter(x => x.id !== data.id)])
                    callback !== undefined && callback()
                    dispatch(Actions.endLoadingAmenitySession(""))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(Actions.endLoadingAmenitySession("ERROR"))
                })
        }
    }

    const handleClickAmenity = () => {
        //setAmenities((prev: Amenity[]) => selected ? [...prev.filter(x => x.id !== data.id)] : [...prev, data])
        if(!selected){
            createAmenityByHouse()
        } else {
            deleteAmenityByHouse()
        }
    }

    return (
        <div onClick={handleClickAmenity} style={{ padding: "10px", border: selected ? "1px solid #009dff" : "1px solid black", margin: "5px", cursor: "pointer" }}>
            {
                data.amenityTranslations &&
                data.amenityTranslations.length > 0 &&
                data.amenityTranslations[0].name
            }
        </div>
    )
}