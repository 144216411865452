import { ChangeEvent, useState, useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import ImageChanger from '../../components/image-changer/image-changer';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from '../../components/bottons/primary-button';
import LoadingModal from '../../components/modals/loading-modal/loading-modal';
import ConfirmModal from '../../components/modals/confirm-modal/confirm-modal';
import Actions from '../../store/actions';
import Api from '../../api';
import { createAccommodationObjectFromArray } from '../../assets/helpers/content-data';
import { Config } from '../../api/config';
import { House } from '../../models/House';
import HouseAccommodationCard from '../../components/cards/house-accommodation-card/house-accommodation-card';

interface ContentData {
    [key: string]: string;
}

interface Props {
    callback: Function
}


function Accommodation() {

    const dispatch = useDispatch();
    const houseSession = useSelector((state: any) => state.houseSession)
    const contentSession = useSelector((state: any) => state.contentSession)
    const userSession = useSelector((state: any) => state.userSession)

    useEffect(() => {
        console.log(houseSession.allHouses)
    }, [houseSession.allHouses])


    const [edit, setEdit] = useState(false)
    const [save, setSave] = useState(false)

    const fileName = "Accommodation"

    const [contentData, setContentData] = useState<ContentData | null>(null)

    const handleEnableEditing = () => {
        setEdit(true)
    }

    const handleSaveChanges = () => {
        setEdit(false)
        setSave(false)
        SetAccommodationPage()
    }

    const SetAccommodationPage = async () => {
        dispatch(Actions.startLoadingContentSession())
        if (contentData !== null)
            await Api.setAccommodationPage(Object.values(contentData))
                .then(response => {
                    dispatch(Actions.setAccommodationContent(createAccommodationObjectFromArray(response.data)))
                    dispatch(Actions.endLoadingContentSession(""))
                    GetHouses()
                })
                .catch(err => {
                    console.log(err)
                    dispatch(Actions.endLoadingContentSession("ERROR"))
                    /*if(err.message === 'Network Error')
                      navigate('/error')*/
                })
    }

    const inputChangeHandler = () => {
        return ({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (contentData !== null)
                setContentData((prev: ContentData | null) => ({
                    ...prev,
                    [name]: value,
                }));
        };
    };

    const GetHouses = async () => {
        dispatch(Actions.startLoadingHouseSession())
        await Api.getHouses()
            .then(response => {
                dispatch(Actions.setAllHouses(response.data))
                dispatch(Actions.endLoadingHouseSession(""))
            })
            .catch(err => {
                console.log(err)
                dispatch(Actions.setAllHouses([]))
                dispatch(Actions.endLoadingHouseSession("ERROR"))
            })
    }

    useEffect(() => {
        if (contentData === null && contentSession.accommodation !== null)
            setContentData(contentSession.accommodation)
        
    }, [contentSession])

    return (
        <>
            <LoadingModal open={contentSession.loading || houseSession.loading} />
            <ConfirmModal open={save} handelNo={() => { setSave(false) }} handleConfirm={handleSaveChanges} />
            {contentData !== null ?
                <>
                    {
                        userSession.data !== "" &&
                        <Grid sx={{ position: "fixed", top: "10px", right: "10px", zIndex: "9999", visibility: save ? "hidden" : "visible" }}>
                            {
                                !edit ?
                                    <PrimaryButton onClick={handleEnableEditing}>Enable editing</PrimaryButton>
                                    :
                                    <PrimaryButton onClick={() => { setSave(true) }}>Save changes</PrimaryButton>
                            }
                        </Grid>
                    }
                    <Grid className="accommodation-header" container sx={{ position: "relative", backgroundImage: `url(${Config.imageBaseURL}${contentData.headerImage})` }}>
                        <Grid container sx={{ textAlign: "center", display: "flex", justifyContent: "center", margin: "50px 0px", alignItems: "center" }}>
                            <Typography variant='h3' sx={{ fontWeight: "bold", color: "white" }}>
                                Accommodation
                            </Typography>
                        </Grid>
                        {
                            edit &&
                            <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
                                <ImageChanger pageFileName={fileName} oldImageName={contentData.headerImage} name='headerImage' onChange={inputChangeHandler()} />
                            </div>
                        }
                    </Grid>
                    {
                        houseSession.allHouses.length > 0 &&
                        houseSession.allHouses.map((house: House, index: number) => {
                            return (
                                <HouseAccommodationCard key={"house" + index} edit={edit} data={house} index={index} />
                            )
                        })
                    }
                </>
                :
                <>
                    <Grid container sx={{ height: "100vh", backgroundColor: "white" }}></Grid>
                </>
            }
        </>
    )
}

export default Accommodation;