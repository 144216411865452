import { createSlice } from '@reduxjs/toolkit'

export const gallerySlice = createSlice({
    name: 'gallery',
    initialState: {
        images: [] as string[],
        loading: false,
        message: '',
        error: false
    },
    reducers: {
        startLoadingGallerySession: (state) => {
            return { ...state, loading: true }
        },
        endLoadingGallerySession: (state, action) => {
            return { ...state, loading: false, message: action.payload }
        },
        setImages: (state, action) => {
            return { ...state, images: action.payload }
        }
    }
})

export const { startLoadingGallerySession, endLoadingGallerySession, setImages } = gallerySlice.actions

export default gallerySlice.reducer