import { Box, Grid, TextField, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import blackLogoImg from "../../assets/images/logo/vectorpaint-black.svg";
import PrimaryButton from "../../components/bottons/primary-button";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../store/actions";
import Api from "../../api";
import LoadingModal from "../../components/modals/loading-modal/loading-modal";
import { useCookies } from "react-cookie";

interface LoginData {
  [key: string]: string;
}

export default function LogIn() {
  const dispatch = useDispatch();
  const userSession = useSelector((state: any) => state.userSession);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["ndToken"]);

  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });

  const inputChangeHandler = () => {
    return ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLoginData((prev: LoginData | null) => ({
        ...prev,
        [name]: value,
      }));
    };
  };

  const handleLogin = async () => {
    dispatch(Actions.startLoadingUserSession());
    await Api.userLogin({
      email: loginData.email,
      password: loginData.password,
    })
      .then((response) => {
        console.log(response.data);
        setCookie("ndToken", response.data.token);
        dispatch(Actions.setUserData(response.data.email));
        navigate("/");
        dispatch(Actions.endLoadingUserSession(""));
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          Actions.endLoadingUserSession("Username or password is incorrect!")
        );
      });
  };

  return (
    <>
      <LoadingModal open={userSession.loading} />
      <Grid
        container
        sx={{ height: "100vh", alignItems: "center", justifyContent: "center" }}
      >
        <Grid
          item
          xs={11}
          md={6}
          lg={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link className="app-bar-link" to="/">
            <img src={blackLogoImg} style={{ width: "90px" }} />
          </Link>
          <Box
            sx={{
              color: "black",
              fontSize: 25,
              fontWeight: "300",
              marginTop: "5px",
              height: "25px",
              padding: "5px 8px ",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Nikiti Dream
          </Box>
          <TextField
            type="text"
            onChange={inputChangeHandler()}
            name="email"
            value={loginData.email}
            variant="outlined"
            label="Email"
            sx={{ width: "90%", marginTop: "20px" }}
          />
          <TextField
            type="password"
            onChange={inputChangeHandler()}
            name="password"
            value={loginData.password}
            variant="outlined"
            label="Password"
            sx={{ width: "90%", marginTop: "20px" }}
          />
          {userSession.message !== "" && (
            <Typography variant="body1" sx={{ fontSize: "12px", color: "red" }}>
              {userSession.message}
            </Typography>
          )}
          <PrimaryButton onClick={handleLogin} sx={{ marginTop: "20px" }}>
            Log In
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
}
