import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { MainThemeColor } from "../../models/constants/Colors";

const SecondaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: MainThemeColor,
  backgroundColor: "white",
  borderRadius: "0px",
  padding: "10px 30px",
  boxShadow: "none",
  border: `1px solid ${MainThemeColor}`,
  width: "100%",
  margin: "0px",

  "&:hover": {
    backgroundColor: MainThemeColor,
    color: "white",
  },
}));

export default SecondaryButton;
