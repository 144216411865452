export function createHomeObjectFromArray(stringArray: string[]): {
  [key: string]: string;
} {
  var keys = [
    "headerImage",
    "promoImage1",
    "promoImage2",
    "accommodationImage1",
    "accommodationImage2",
    "feature1Image",
    "feature2Image",
    "feature3Image",
    "feature4Image",
    "headerTitle",
    "headerText",
    "promoTitle",
    "promoText",
    "promoTitle2",
    "accommodationTitle",
    "accommodationText",
    "feature1Title",
    "feature2Title",
    "feature3Title",
    "feature4Title",
    "feature1Text",
    "feature2Text",
    "feature3Text",
    "feature4Text",
  ];

  const obj: { [key: string]: string } = stringArray.reduce(
    (accumulator: any, currentValue, index) => {
      accumulator[`${keys[index]}`] = currentValue;
      return accumulator;
    },
    {}
  );

  return obj;
}

export function createAccommodationObjectFromArray(stringArray: string[]): {
  [key: string]: string;
} {
  var keys = ["headerImage"];

  const obj: { [key: string]: string } = stringArray.reduce(
    (accumulator: any, currentValue, index) => {
      accumulator[`${keys[index]}`] = currentValue;
      return accumulator;
    },
    {}
  );

  return obj;
}

export function createFacilitiesObjectFromArray(stringArray: string[]): {
  [key: string]: string;
} {
  var keys = [
    "headerImage",
    "image1",
    "image2",
    "image3",
    "image4",
    "title1",
    "title2",
    "text1",
    "text2",
  ];

  const obj: { [key: string]: string } = stringArray.reduce(
    (accumulator: any, currentValue, index) => {
      accumulator[`${keys[index]}`] = currentValue;
      return accumulator;
    },
    {}
  );

  return obj;
}

export function createContactObjectFromArray(stringArray: string[]): {
  [key: string]: string;
} {
  var keys = [
    "headerImage",
    "text1",
    "phone",
    "email",
    "facebook",
    "instagram",
  ];

  const obj: { [key: string]: string } = stringArray.reduce(
    (accumulator: any, currentValue, index) => {
      accumulator[`${keys[index]}`] = currentValue;
      return accumulator;
    },
    {}
  );

  return obj;
}

export function createExperiencesObjectFromArray(stringArray: string[]): {
  [key: string]: string;
} {
  var keys = [
    "headerImage",
    "image1",
    "image2",
    "image3",
    "image4",
    "image5",
    "title1",
    "title2",
    "title3",
    "title4",
    "subTitle1",
    "subTitle2",
    "subTitle3",
    "subTitle4",
    "subTitle5",
    "subTitle6",
    "subTitle7",
    "subTitle8",
    "text1",
    "text2",
    "text3",
    "text4",
    "text5",
    "text6",
    "text7",
    "text8",
    "text9",
  ];

  const obj: { [key: string]: string } = stringArray.reduce(
    (accumulator: any, currentValue, index) => {
      accumulator[`${keys[index]}`] = currentValue;
      return accumulator;
    },
    {}
  );

  return obj;
}
