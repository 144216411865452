import { useState, useEffect } from 'react'
import { Grid, Typography } from "@mui/material";
import imagePlaceholder from '../../../assets/images/placeholders/No-Image-Placeholder.jpg';
import { Link } from "react-router-dom";
import { House } from "../../../models/House";
import { Config } from "../../../api/config";
import { useDispatch } from "react-redux";
import Actions from "../../../store/actions";
import Api from "../../../api";

interface Props {
    data: House,
    index: number,
    edit: boolean
}

export default function HouseAccommodationCard({ data, index, edit }: Props) {

    const dispatch = useDispatch()

    const translationData = data.houseTranslations !== null && data.houseTranslations.length > 0 ? data.houseTranslations[0] : null

    const [name, setName] = useState(translationData?.name)
    const [shortDescription, setShortDescription] = useState(translationData?.shortDescription)

    const updateHouseTranslation = async () => {
        if (translationData !== null && shortDescription !== undefined && name !== undefined && (shortDescription !== translationData.shortDescription || name !== translationData.name)) {
            dispatch(Actions.startLoadingHouseSession())
            await Api.updateHouseTranslation({
                id: translationData.id,
                name: name,
                shortDescription: shortDescription,
                longDescription: translationData.longDescription,
                languageId: 1,
                houseId: translationData.houseId,
                language: null,
                house: null
            })
                .then(response => {
                    console.log(response)
                    dispatch(Actions.endLoadingHouseSession(""))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(Actions.endLoadingHouseSession("ERROR"))
                })
        }
    }

    useEffect(() => {
        if (!edit) {
            updateHouseTranslation()
        }
    }, [edit])
    return (
        <>
            {
                index % 2 === 0 ?
                    <Grid container sx={{ alignContent: "center", justifyContent: "center", flexWrap: "wrap-reverse" }}>
                        <Grid item xs={10} md={8} xl={4} lg={5} sx={{ position: "relative", margin: { xs: "20px auto", lg: "100px 0px 120px 0px" }, justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                            <div style={{ backgroundImage: `url(${data.images && data.images.length > 0 ? Config.houseImageBaseURL + data.images[0].smallImageName : imagePlaceholder})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginBottom: "50px", width: "48%", aspectRatio: "3/4" }}>

                            </div>
                            <div style={{ backgroundImage: `url(${data.images && data.images.length > 1 ? Config.houseImageBaseURL + data.images[1].smallImageName : imagePlaceholder})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginTop: "50px", width: "48%", aspectRatio: "3/4" }}>

                            </div>
                            {
                                edit &&
                                <div style={{ position: "absolute", backgroundColor: "rgba(0,0,0,.7)", padding: "20px" }}>
                                    <Typography variant='body1' sx={{ fontWeight: "bold", color: "orange", margin: "20px 10px 0px 10px" }}>
                                        These photos can be changed on the 'Details' page, to get to the 'Details' page click on 'Discover More...'
                                    </Typography>
                                </div>
                            }
                        </Grid>
                        <Grid item xs={10} md={8} xl={3} lg={3} sx={{ margin: { xs: "20px auto", lg: "150px 0px 150px 50px" } }}>
                            {
                                !edit ?
                                    <>
                                        <Typography variant='h3'>
                                            {data.houseTranslations && data.houseTranslations.length > 0 && data.houseTranslations[0].name}
                                        </Typography>
                                        <Typography variant='body1' sx={{ marginTop: "50px" }}>
                                            {data.houseTranslations && data.houseTranslations.length > 0 && data.houseTranslations[0].shortDescription}
                                        </Typography>
                                    </>
                                    :
                                    <>
                                        <input className='admin-input' value={name} onChange={(e) => { setName(e.target.value) }} />
                                        <textarea className='admin-textarea' value={shortDescription} onChange={(e) => { setShortDescription(e.target.value) }} />
                                    </>
                            }
                            <Typography variant='body1' sx={{ marginTop: "50px" }}>
                                <Link to={`/details/${data.houseTranslations && data.houseTranslations.length > 0 && data.houseTranslations[0].name}`}>Discover More...</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    :
                    <Grid container sx={{ alignContent: "center", justifyContent: "center", backgroundColor: "rgba(130,139,178,0.1)" }}>
                        <Grid item xs={10} md={8} xl={3} lg={3} sx={{ margin: { xs: "20px auto", lg: "150px 50px 150px 0px" } }}>
                            {
                                !edit ?
                                    <>
                                        <Typography variant='h3'>
                                            {data.houseTranslations && data.houseTranslations.length > 0 && data.houseTranslations[0].name}
                                        </Typography>
                                        <Typography variant='body1' sx={{ marginTop: "50px" }}>
                                            {data.houseTranslations && data.houseTranslations.length > 0 && data.houseTranslations[0].shortDescription}
                                        </Typography>
                                    </>
                                    :
                                    <>
                                        <input className='admin-input' value={name} onChange={(e) => { setName(e.target.value) }} />
                                        <textarea className='admin-textarea' value={shortDescription} onChange={(e) => { setShortDescription(e.target.value) }} />
                                    </>
                            }
                            <Typography variant='body1' sx={{ marginTop: "50px" }}>
                                <Link to={`/details/${data.houseTranslations && data.houseTranslations.length > 0 && data.houseTranslations[0].name}`}>Discover More...</Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={10} md={8} xl={4} lg={5} sx={{position: "relative", margin: { xs: "20px auto", lg: "100px 0px 120px 0px" }, justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                            <div style={{ backgroundImage: `url(${data.images && data.images.length > 0 ? Config.houseImageBaseURL + data.images[0].smallImageName : imagePlaceholder})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginBottom: "50px", width: "48%", aspectRatio: "3/4" }}>

                            </div>
                            <div style={{ backgroundImage: `url(${data.images && data.images.length > 1 ? Config.houseImageBaseURL + data.images[1].smallImageName : imagePlaceholder})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginTop: "50px", width: "48%", aspectRatio: "3/4" }}>

                            </div>
                            {
                                edit &&
                                <div style={{ position: "absolute", backgroundColor: "rgba(0,0,0,.7)", padding: "20px" }}>
                                    <Typography variant='body1' sx={{ fontWeight: "bold", color: "orange", margin: "20px 10px 0px 10px" }}>
                                        These photos can be changed on the 'Details' page, to get to the 'Details' page click on 'Discover More...'
                                    </Typography>
                                </div>
                            }
                        </Grid>
                    </Grid>
            }
        </>
    )
}