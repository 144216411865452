import { configureStore } from '@reduxjs/toolkit'
import houseReducer from './slices/houseSlice'
import reservationReducer from './slices/reservationSlice'
import contentReducer from './slices/contentSlice'
import amenityReducer from './slices/amenitySlice'
import galleryReducer from './slices/gallerySlice'
import userReducer from './slices/userSlice'
import beachReducer from './slices/beachSlice'

const store = configureStore({
    reducer: {
      houseSession: houseReducer,
      reservationSession: reservationReducer,
      contentSession: contentReducer,
      amenitySession: amenityReducer,
      gallerySession: galleryReducer,
      userSession: userReducer,
      beachSession: beachReducer
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),  
})

export default store;