import { useCallback, useState, useRef } from 'react'
import PrimaryButton from '../bottons/primary-button'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../store/actions'
import Api from '../../api'
import LoadingModal from '../modals/loading-modal/loading-modal'
import ConfirmModal from '../modals/confirm-modal/confirm-modal'
import { Config } from '../../api/config'
import { Grid, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'

interface Props {
    data?: { id: number, name: string, description: string, images: string },
    setBeachToEdit?: Function
}

export default function BeachCreator({ data, setBeachToEdit }: Props) {

    const dispatch = useDispatch()
    const beachSession = useSelector((state: any) => state.beachSession)
    const [name, setName] = useState(data !== undefined ? data.name : "")
    const [description, setDescription] = useState(data !== undefined ? data.description : "")
    const [step, setStep] = useState(0)
    const [id, setId] = useState(data !== undefined ? data.id : 0)
    const [images, setImages] = useState<string[]>(data !== undefined ? (data.images !== "" ? data.images.split(';') : []) : [])
    const [imageToDelete, setImageToDelete] = useState("")

    const createBeach = async () => {
        dispatch(Actions.startLoadingBeachSession())
        await Api.addBeach({
            name: name,
            description: description,
            images: ""
        })
            .then(response => {
                console.log(response.data)

                setId(response.data.id)
                setStep(1)
                GetBeachesCallback()
                dispatch(Actions.endLoadingBeachSession(""))
            })
            .catch(err => {
                console.log(err)
                dispatch(Actions.endLoadingBeachSession("ERROR"))
            })
    }

    const updateBeach = async () => {
        dispatch(Actions.startLoadingBeachSession())
        await Api.updateBeach({
            id: id,
            name: name,
            description: description,
            images: images.join(";")
        })
            .then(response => {
                setStep(1)
                GetBeachesCallback()
                dispatch(Actions.endLoadingBeachSession(""))
            })
            .catch(err => {
                console.log(err)
                dispatch(Actions.endLoadingBeachSession("ERROR"))
            })
    }

    const GetBeachesCallback = useCallback(async () => {
        dispatch(Actions.startLoadingBeachSession())
        await Api.getBeaches()
            .then(response => {
                console.log(response.data)
                dispatch(Actions.setBeaches(response.data))
                dispatch(Actions.endLoadingBeachSession(""))
            })
            .catch(err => {
                console.log(err)
                dispatch(Actions.endLoadingBeachSession("ERROR"))
            })
    }, [dispatch]);

    const [imageFile, setImageFile] = useState<any>(null)
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);

    const handleClick = () => {
        if (hiddenFileInput !== null && hiddenFileInput.current !== null)
            hiddenFileInput.current.click();
    };

    const handleImageUpload = async () => {
        dispatch(Actions.startLoadingBeachSession())
        await Api.addBeachImage(
            id,
            imageFile
        )
            .then(response => {
                setImages((perv: string[]) => [...perv, response.data])
                setImageFile(null)
                GetBeachesCallback()
                dispatch(Actions.endLoadingHouseSession(""))
            })
            .catch(error => {
                console.log(error)
                dispatch(Actions.endLoadingHouseSession("ERROR"))
            })
    }

    const handleNo = () => {
        setImageFile(null)
        setImageToDelete("")
    }

    const handleDeleteImage = async () => {
        dispatch(Actions.startLoadingBeachSession())
        await Api.deleteBeachImage(
            imageToDelete,
            id
        )
            .then(response => {
                setImages(images.filter(x => x !== imageToDelete))
                setImageToDelete("")
                GetBeachesCallback()
                dispatch(Actions.endLoadingHouseSession(""))
            })
            .catch(error => {
                console.log(error)
                dispatch(Actions.endLoadingHouseSession("ERROR"))
            })
    }

    const handleFinish = () => {
        setName("")
        setDescription("")
        setStep(0)
        setId(0)
        setImages([])
        setImageToDelete("")
        setBeachToEdit !== undefined && setBeachToEdit(null)
    }

    return (
        <>
            <LoadingModal open={beachSession.loading} />
            <div style={{ width: "100%", marginBottom: "20px", padding: "20px", boxSizing: "border-box", backgroundColor: "white", border: "1px dashed #009dff", borderRadius: "5px" }}>
                {
                    data !== undefined ?
                        <Typography variant='h5'>
                            Edit beach
                        </Typography>
                        :
                        <Typography variant='h5'>
                            Add new beach
                        </Typography>
                }
                {
                    step == 0 &&
                    <>
                        <input className='admin-input' placeholder='Name' type='text' value={name} onChange={(e) => { setName(e.target.value) }} />
                        <br /><br />
                        <input className='admin-input' placeholder='Description' type='text' value={description} onChange={(e) => { setDescription(e.target.value) }} />
                        <br /><br />
                        {
                            data !== undefined ?
                                <PrimaryButton onClick={updateBeach}>Next</PrimaryButton>
                                :
                                <PrimaryButton onClick={createBeach}>Next</PrimaryButton>
                        }
                    </>
                }
                {
                    step == 1 &&
                    <>
                        <ConfirmModal open={imageFile !== null} handleConfirm={handleImageUpload} handelNo={handleNo} />
                        <ConfirmModal open={imageToDelete !== ""} handleConfirm={handleDeleteImage} handelNo={handleNo} />
                        <Grid container>
                            {
                                images.map((image: string) => {
                                    return (
                                        <Grid xs={11} md={3} sx={{ backgroundImage: `url(${Config.beachImageBaseURL}${image})`, position: "relative", aspectRatio: "1", backgroundSize: "cover", backgroundPosition: "50% 50%", margin: "20px" }}>
                                            <Delete onClick={() => { setImageToDelete(image) }} sx={{ position: "absolute", bottom: "10px", right: "10px", color: "white", border: "1px solid white", padding: "10px", backgroundColor: "rgba(0,0,0,.6)", cursor: "pointer" }} />
                                        </Grid>
                                    )
                                })
                            }

                        </Grid>
                        <input
                            ref={hiddenFileInput}
                            style={{ overflow: "hidden", width: "0px", height: "0px" }}
                            type='file'
                            onChange={(e) => { e.target.files !== null && e.target.files.length > 0 && setImageFile(e.target.files[0]) }}
                        />
                        <PrimaryButton onClick={handleClick}>Add Image</PrimaryButton>
                        <br /> <br />
                        <PrimaryButton onClick={handleFinish}>Finish</PrimaryButton>
                    </>
                }
            </div>
        </>
    )
}