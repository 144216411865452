import { Grid } from "@mui/material";
import { Config } from "../../../api/config";
import { Delete } from '@mui/icons-material';
import Actions from "../../../store/actions";
import Api from "../../../api";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../modals/confirm-modal/confirm-modal";
import { useState } from "react";
import { Image } from "../../../models/Image";

interface Props {
    image: any,
    setImages: Function,
    deleteCallback?: Function
}

export default function ImageCard({ image, setImages, deleteCallback }: Props) {

    const dispatch = useDispatch()

    const [imageId, setImageId] = useState(null)

    const handleDeleteImage = async () => {
        dispatch(Actions.startLoadingHouseSession())
        await Api.deleteImage(imageId)
            .then(response => {
                
                setImages((perv: Image[]) => [...perv.filter(x=>x.id !== imageId)])
                setImageId(null)
                deleteCallback !== undefined && deleteCallback()
                dispatch(Actions.endLoadingHouseSession(""))
            })
            .catch(error => {
                console.log(error)
                dispatch(Actions.endLoadingHouseSession("ERROR"))
            })
    }

    const handleNo = () => {
        setImageId(null)
    }

    return (
        <>
            <ConfirmModal open={imageId !== null} handelNo={handleNo} handleConfirm={handleDeleteImage} />
            <Grid xs={11} md={3} sx={{ backgroundImage: `url(${Config.houseImageBaseURL}${image.smallImageName})`, position: "relative", aspectRatio: "1", backgroundSize: "cover", backgroundPosition: "50% 50%", margin: "20px" }}>
                <Delete onClick={()=>{setImageId(image.id)}} sx={{ position: "absolute", bottom: "10px", right: "10px", color: "white", border: "1px solid white", padding: "10px", backgroundColor: "rgba(0,0,0,.6)", cursor: "pointer" }} />
            </Grid>
        </>
    )
}