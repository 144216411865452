import { useState } from 'react'
import { useDispatch } from "react-redux"
import Actions from "../../../store/actions"
import Api from "../../../api"
import { Feature } from '../../../models/Feature'
import { Delete, Edit } from '@mui/icons-material';
import PrimaryButton from '../../bottons/primary-button'
import ConfirmModal from '../../modals/confirm-modal/confirm-modal'

interface Props {
    data: Feature,
    setFeatures: Function,
    getData?: Function
}

export default function FeatureCard({ data, setFeatures, getData }: Props) {

    const dispatch = useDispatch()
    const [edit, setEdit] = useState(false)
    const [name, setName] = useState("")
    const [save, setSave] = useState(false)

    const deleteFeature = async () => {
        dispatch(Actions.startLoadingHouseSession())
        await Api.deleteFeature(data.id)
            .then(response => {
                console.log(response)
                setFeatures((prev: Feature[]) => [...prev.filter(x => x.id !== data.id)])
                getData !== undefined && getData()
                dispatch(Actions.endLoadingHouseSession(""))
            })
            .catch(err => {
                console.log(err)
                dispatch(Actions.endLoadingHouseSession("ERROR"))
            })
    }

    const updateFeature = async () => {
        dispatch(Actions.startLoadingHouseSession())
        await Api.updateFeature({
            id: data.id,
            name: name,
            houseId: data.houseId,
            house: null
        })
            .then(response => {
                console.log(response)
                getData !== undefined && getData()
                dispatch(Actions.endLoadingHouseSession(""))
            })
            .catch(err => {
                console.log(err)
                dispatch(Actions.endLoadingHouseSession("ERROR"))
            })
    }

    const handleClickDelete = () => {
        deleteFeature()
    }

    const handleClickEdit = () => {
        setName(data.name)
        setEdit(true)
    }

    const handleConfirm = () => {
        setSave(false)
        updateFeature()
    }

    const handleNo = () => {
        setSave(false)
        setEdit(false)
    }

    return (
        <>
        <ConfirmModal open={save} handelNo={handleNo} handleConfirm={handleConfirm}/>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", border: "1px solid #009dff", margin: "5px", cursor: "pointer" }}>
                {edit ?
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <input type='text' style={{ width: "100%", boxSizing: "border-box" }} className='admin-input' value={name} onChange={(e) => { setName(e.target.value) }} />
                        <br />
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <PrimaryButton onClick={()=>{setSave(true)}}>Save</PrimaryButton>
                            <PrimaryButton onClick={()=>{setEdit(false);setName("")}}>Cancel</PrimaryButton>
                        </div>
                    </div>
                    :
                    <>
                        <div>
                            {data.name}
                        </div>
                        <div style={{ display: "flex", alignItems: "center", }}>
                            <Edit onClick={handleClickEdit} sx={{ color: "black", padding: "5px", border: "1px solid black", borderRadius: "5px", marginRight: "5px" }} />
                            <Delete onClick={handleClickDelete} sx={{ color: "black", padding: "5px", border: "1px solid black", borderRadius: "5px" }} />
                        </div>
                    </>
                }
            </div>
        </>
    )
}