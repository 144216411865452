import { Box, Grid, Typography } from "@mui/material";
import { LocationOn } from '@mui/icons-material';
import { useCallback, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Config } from "../../../api/config";
import PrimaryButton from "../../bottons/primary-button";
import Actions from "../../../store/actions";
import { useDispatch } from "react-redux";
import Api from "../../../api";

interface Props {
    id: number,
    images: string[],
    name: string,
    description: string,
    setBeachToEdit: Function,
    edit: boolean
}

export default function ExperienceBeachCard({ id, images, name, description, setBeachToEdit, edit }: Props) {

    const dispatch = useDispatch()
    const [hover, setHover] = useState(false)

    const handleMouseIn = () => {
        setHover(true)
    }

    const handleMouseOut = () => {
        setHover(false)
    }

    const GetBeachesCallback = useCallback(async () => {
        dispatch(Actions.startLoadingBeachSession())
        await Api.getBeaches()
            .then(response => {
                console.log(response.data)
                dispatch(Actions.setBeaches(response.data))
                dispatch(Actions.endLoadingBeachSession(""))
            })
            .catch(err => {
                console.log(err)
                dispatch(Actions.endLoadingBeachSession("ERROR"))
            })
    }, [dispatch]);

    const handleDelete = async () => {
        dispatch(Actions.startLoadingBeachSession())
        await Api.deleteBeach(
            id
        )
            .then(response => {
                GetBeachesCallback()
                dispatch(Actions.endLoadingHouseSession(""))
            })
            .catch(error => {
                console.log(error)
                dispatch(Actions.endLoadingHouseSession("ERROR"))
            })
    }
    return (
        <Grid className='experience-beach-card' xs={12} sm={6} lg={4} sx={{ padding: "15px" }}>
            <Box sx={{ width: "100%", aspectRatio: "5/4", backgroundColor: "white", backgroundSize: "cover", backgroundPosition: "50% 50%" }}>
                <Carousel className="experiences-images" navButtonsAlwaysVisible indicators sx={{ padding: "0px", margin: "0px" }}>
                    {
                        images !== null &&
                        images.map((image, index) => (
                            <Box key={index} sx={{ backgroundImage: `url('${Config.beachImageBaseURL}${image}')`, backgroundPosition: "50% 50%", backgroundSize: "cover", aspectRatio: "4/3" }}>

                            </Box>
                        ))
                    }
                </Carousel>
                {/*<Box sx={{transform: hover? "scaleY(1)":"scaleY(0)", transformOrigin: "top", transition: ".3s", width: "100%", height: "100%", boxSizing: "border-box", padding: "30px", backgroundColor: "rgba(0,0,0,.6)", color: "white" }}>
                        <Typography variant='body1' sx={{}}>
                            {description}
                        </Typography>
    </Box>*/}
            </Box>
            <Box sx={{ width: "100%", fontWeight: "bold", aspectRatio: "5/1", backgroundColor: "white", display: "flex", alignItems: "center" }}>
                <LocationOn fontSize='medium' sx={{ color: "#8f9195", marginLeft: "15px" }} />{name}
            </Box>
            <Box sx={{ width: "100%", padding: "0px 15px 15px 15px", boxSizing: "border-box", backgroundColor: "white", display: "flex", alignItems: "center" }}>
                {description}
            </Box>
            {
                edit &&
                <>
                    <Box sx={{ width: "100%", padding: "0px 15px 15px 15px", boxSizing: "border-box", backgroundColor: "white", display: "flex", alignItems: "center" }}>
                        <PrimaryButton onClick={() => { setBeachToEdit({ id: id, images: images.join(';'), name: name, description: description }) }}>Edit</PrimaryButton>
                    </Box>
                    <Box sx={{ width: "100%", padding: "0px 15px 15px 15px", boxSizing: "border-box", backgroundColor: "white", display: "flex", alignItems: "center" }}>
                        <PrimaryButton onClick={handleDelete}>Delete</PrimaryButton>
                    </Box>
                </>
            }

        </Grid>
    )
}