export function PrivacyPolicy(){
    return(
        <div
        style={{display:'flex', alignItems: 'center' , flexDirection: 'column', paddingTop: '100px'}}
        >
            <div style={{width: '70%'}}>

            <h3>Nikiti Dream Villas Privacy Policy</h3>
            <p>In this charter <strong>“Nikiti Dream Villas”, “we”, “our”, or “us”</strong> mean: 5M Greece Single Member P.C., located in Nikiti, Greece, tax number: 801969527 with the tax office in Poligiros, with General Commercial Registry Number: 167266806000. Our website is at: <a href='https://nikitidream.com'>https://nikitidream.com</a> (hereinafter referred to as <strong>Website</strong>).</p>
            
            <h2>Preliminaries</h2>
            <p>Our privacy policy explains how we collect, use, protect and handle your data which you share with us on our Website, in communication with us or at the location of our accommodations.</p>
            <p>When booking our accommodations, you are asked to enter your name, email address, phone number and other details, so that we can offer you our service and ensure you get the best experience.</p>
            <p>We take the security of your privacy and confidentiality very seriously, and we pledge to keep all details of our customers confidential. Our policy complies with the EU General Data Protection Regulation (GDPR), the applicable Greek Law on the protection of personal data (No 4624/2019, No 2472/1997, No 3471/2006, as applicable, etc.) as well as the opinions and guidelines of the Hellenic Data Protection Authority and the European Data Protection Board. </p>
            <p>It is important that you read this privacy policy, together with any other privacy notices we may show you from time to time, so that you are fully aware of how and why we are using your personal data.</p>
        
            <h2>Definitions</h2>
            <p><u>‘PERSONAL DATA’</u> means any information relating to an identified or identifiable natural person (‘data subject’). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person, <u>‘SPECIAL CATEGORIES OF PERSONAL DATA’</u> are among others genetic, biometric, data concerning health, racial or ethnic origin etc.,</p>
            <p><u>‘PERSONAL DATA PROCESSING’</u> means any operation or set of operations which is performed on personal data, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction,</p>
            <p><u>‘CONTROLLER’</u> means the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data,</p>
            <p><u>‘PROCESSOR’</u> means a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.
            </p>

            <h2>Children</h2>
            <p>With guest reservations we do not seek to collect personal information about children under 13, but you must be 18 or older to use the Website. We may collect information from parents or guardians about family reservations with their consent. If we learn and have actual knowledge of a child providing information through the Website, we will delete such information as soon as possible. We will also honor requests by a parent or guardian to review or delete information about their child. This Website is not intended for children and we do not knowingly collect data relating to children. </p>
        
            <h2>Basis for processing</h2>
            <p>We may proceed to the collection, usage, storage and transfer of personal data provided for the following purposes: managing booking requests, sending the confirmation or documentation for the booking made, promotions, marketing & advertisement, managing subscription and/or unsubscription to the newsletter, analyzing Website usage and checking user preferences and behavior.</p>
            <p><strong>Nikiti Dream Villas</strong> will process the following categories of user data:</p>

            <p> •	Identifying data: name, surname, nationality
            <br/> •	Contact details: email address, telephone number and billing address
            <br/> •	Transactional data for goods and services: Products and services purchased or in which you show interest
            <br/> •	Data in relation to invoicing (e.g. Tax Number, Tax Authority, data from the bank card used for payment)
            <br/> •	Stay preferences
            <br/> •	Financial data: bank account and payment card details
            <br/> •	Browsing data: internet protocol (IP) address, zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.
            <br/> •	CCTV system data in order to guarantee the security of our employees, establishments and equipment. Our legal basis for this data collection is the legitimate interest.
            </p>

            <p>Upon departure, we will ask you to fill in an anonymous questionnaire to rate your experience at <strong>Nikiti Dream Villas</strong>. By agreeing to complete the guest review, you give your consent for the review to be uploaded on our website. We reserve the right to adjust or remove forms at our sole discretion.</p>
            <p>If you no longer wish to receive our newsletter and other promotional communications, you can simply click on the unsubscribe link or send us email at <u>nikitidream@gmail.com</u>.</p>
            <p>We collect and process your personal data each time you use our services (either those services are provided directly by us or by other companies or agents acting on our behalf), in general when you interact with us, when you use our sites, or when you use our call centers or our mobile and tablets apps. Also, other information about you is collected by us:</p>
 
            <p> •	Through the browser cookies that you use when navigating our Websites,
            <br/> •	When you use contact forms to request more information or post a comment.
            <br/> •	When we communicate with you.
            <br/> •	When connecting to our Hotels wi-fi.
            <br/> •	Directly from you
            <br/> •	Legal purposes
            <br/> •	Marketing
            <br/> •	Images through our video-surveillance systems
            </p>
            <p> We do not ordinarily collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data) nor do we collect any information about criminal convictions and offences. The only exceptions to this are: (i) if you provide health data to us as part of a booking, we will use this to perform our contract with you, and we use your explicit consent to use your health data or (ii) you have made the special category data obviously public (iii) in case of extremely urgent and extraordinary circumstances (eg. health crisis) to ensure the public’s interest and public’s health.</p>
            
            <h2>Comments</h2>
            <p>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.</p>
            <p> An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.</p>
            <p> If you leave a comment, the comment and its metadata are retained indefinitely. This is done so that we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue. Your data will be held for the duration of the contractual arrangement and for the duration of any civil proceedings that might arise from it after it is terminated. Data given for the purpose of sending out newsletters or promotional deals would be kept for as long as the user has not revoked their consent. Website administrators can also see and edit that information.</p>
            <p> Visitor comments may be checked through an automated spam detection service.</p>
        
            <h2>Social media</h2>
            <p>Our website may also contain plug-ins and other features that integrate third-party social media platforms into our website. You will be able to activate them manually. If you do so, the third parties who operate these platforms may be able to identify you, they may be able to determine how you use this website and they may link and store this information with your social media profile. Please consult the data protection policies of these social media platforms to understand what they will be doing with your personal data. If you activate these plug-ins and other features, you will be doing so at your own risk.</p>
            <p> Any data you provide to us when you visit our Website on social media, such as comments, videos, images, “likes”, public messages, etc., are published on the platform of the social media of your choice and are not used or processed by us for purposes other than your information regarding our promotional activities, such as e.g. discounts, special offers, contests that we may organize, but also in the context of your service, when you wish to get in touch with us in this way. The processing of your personal data is carried out based on art. 6 par. 1 f GDPR, in the context of the provision of our services to you.</p>
            <p> For more information regarding the processing of your data by the providers of social media platforms and in general about your rights, please refer to the respective Privacy or Security Policies of each provider.</p>
        
            <h2>Links to other websites</h2>
            <p>Our website contains links to other websites. Nikiti Dream Villas is not responsible for the privacy practices of third parties or their websites, even if they link from the Website. This Privacy Policy applies solely to information collected by our Website. To the extent that you disclose your information to other parties, different rules may apply to their use or disclosure of information. We urge you to read the privacy and security policies of any external websites before providing any personal information while accessing those websites.</p>
            
            <h2>Media</h2>
            <p>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.</p>
            
            <h2>Cookies</h2>
            <p>After you visit a website, your browser saves a small text file called a cookie to your computer’s hard drive. They allow information collected on one website to be saved before it is needed on another, enabling a website to provide you with a personalized experience and providing the website owner with statistics on how you use the site in order for it to improve.</p>
            <p>Cookies are used on our Website. They are put by software that runs on our servers as well as software that runs on the servers of third-party vendors whose services we use. You will be asked whether or not you want to accept cookies. We can only record that you have not given your consent to their use for any other reason if you refuse to allow them. If you refuse to accept cookies, you will be restricted in your ability to use any of the features of our Website.</p>
            <p>We use cookies in the following ways: to monitor how you use our Website, to keep track of whether you’ve seen unique messages on our website, to keep track of your responses to surveys and questionnaires you complete on our Website.</p>
            <p>If you leave a comment on our Website you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.</p>
            <p>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</p>

            <h2>Embedded content from other websites</h2>
            <p>Articles on this Website may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>
            <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>

            <h2>Who we share your data with</h2>
            <p>In order to process your bookings, provide information about personalized and individualized services/offers, we can share your personal information with third parties who provide us with booking and reservation management, payment related, data management, customer relationship management, marketing and advertisement, information technology services, catering, administration services, or other services you request.</p>

            <h2>How long we retain your data</h2>
            <p>We maintain your personal data solely as long as needed so that the purposes for which they have been collected are fulfilled (e.g. completion of legal/tax procedure). In addition, depending on the quantity, the nature and the sensitivity of the personal data, as well as the purposes for which we process them, we decide the appropriate time period of their storage.</p>
            <p>We are entitled to anonymize your data, so that they cannot be associated with you, for the purpose of being used for research or statistical purposes, so we may use this information indefinitely, without further notice to you.</p>

            <h2>Security</h2>
            <p>We use reasonable safeguards to protect personal information against loss and theft, including encryption technology, restricted access, “firewalls,” and Secure Socket Layers (SSL). Our Website is hosted by a commercial cloud provider, who applies industry-standard security measures, and all transactions are processed there and are not stored or processed on our servers. However, security over the internet cannot be guaranteed. Nikiti Dream Villas does not assume any responsibility for any harm, loss, or damage you may experience or incur by the sending of personal or confidential information over the internet, and you should take your own measures to protect your sensitive information. If you have any questions about the security of our Website, contact us through our Website.</p>

            <h2>CCTV Systems</h2>
            <p>This section sets out the appropriate actions and procedures which Nikiti Dream Villas follows in respect of the use of closed circuit television surveillance systems (CCTV Systems) at our premises.</p>
            <p>Please note that the entrance, parking, barbeque and storage areas are monitored by CCTV 24 hours a day. Nikiti Dream Villas reserves the right for its employees and contractors to review footage as required and by making a reservation and entering onto our sites you consent to your image being recorded and reviewed. Recorded CCTV footage will be stored securely and retained in compliance with relevant laws. We place signage at the entrance and exit points, and the monitored areas to alert you that a CCTV installation is in use.</p>
            <p>The purpose of the use of the CCTV Systems and the collection and processing of CCTV images is for the prevention or detection of crime or disorder, apprehension and prosecution of offenders (including use of images as evidence in criminal proceedings), and the protection of our property and assets. In pursuit of our legitimate interests, to record CCTV footage to ensure the safety and security of our premises, staff and customers; our legitimate interest consists in the necessity to protect our property and the goods located in it from illegal acts, such as theft. The same applies to the safety of life, physical integrity, health as well as the property of our staff, clients and other third parties legally located in the monitored area.</p>
            <p>We only collect image data and limit the surveillance to areas where we have assessed an increased likelihood of illegal activity, without focusing on areas where the privacy of the persons being monitored may be severely restricted, considering their right to respect for personal data.</p>
            <p>We may collect, use, disclose, transfer, and carry out any operation, including but not limited to recording, holding, adapting, altering, modifying, destroying, deleting, retrieving, combining, copying, transmitting, storing, extracting, amending, or adding, related to your CCTV Data and other Personal Data concerning you, for the “CCTV Monitoring Purposes” as listed below:</p>
            
            <p> •	to protect your health and personal safety, including your belongings
            <br/> •	to protect our buildings, facilities and assets from damage, disruption, vandalism, and other crime
            <br/> •	to support law enforcement organizations in the deterrent, prevention, detection, and prosecution of crime
            <br/> •	to assist in the effective resolution of disputes which arise in the course of disciplinary or grievance proceedings
            <br/> •	to assist in the investigation; and
            <br/> •	to assist in the establishment or defense of any civil litigation, including but not limited to employment proceedings.
            </p>
            <p>We will not collect, use, disclose process, transfer, and carry out any operation, including but not limited to recording, holding, adapting, altering, modifying, destroying, deleting, retrieving, combining, copying, transmitting, storing, extracting, amending, or adding, related to your CCTV Data and other Personal Data concerning you, <strong>without consent</strong> <u>except for the following:</u> vital interest, legitimate interest or legal obligations.</p>
            <p>All staff of Nikiti Dream Villas are made aware of the sensitivity of handling CCTV images and recordings.</p>
            <p>CCTV storage material is accessible only by our competent / authorized personnel and associates who are in charge of the security of the space. This material shall not be transmitted to third parties, except in the following cases:</p>
            
            <p>(a) to the competent judicial, prosecutorial and police authorities when it contains information necessary for the investigation of a criminal offense involving persons or property of the controller.
            <br/>(b) to the competent judicial, prosecutorial and police authorities when requesting data, lawfully, in the performance of their duties, and
            <br/>(c) to the victim or perpetrator of a crime, in the case of data which may constitute evidence of the act.
            </p>
            <p>Prior to any camera installation, we ensure that the installation complies with this policy and that the use of any camera is justified, necessary and proportionate. Our Data Protection Officer will regularly assess whether the use of any camera and the CCTV System as a whole continues to be justified, necessary and proportionate.</p>
            <p>The images captured by the CCTV is retained for a maximum of six months from the date of recording. In the event that during this period an incident occurs, we isolate part of the video and store it for a maximum of one year, in order to investigate the incident and initiate legal proceedings to pursue our legitimate interests. We will retain your CCTV Data in our system for the period necessary to fulfil our duties to achieve the CCTV Monitoring Purposes. When we are no longer permitted by the applicable laws to store  our CCTV Data, we will remove it from our systems and records. In the event a judicial or disciplinary action is initiated, your CCTV Data may be stored until the end of such action, including any potential periods for appeal, and will then be deleted or archived as permitted by the applicable laws.</p>
            
            <p>We will keep CCTV Data concerning you confidential and will not disclose or transfer it except to our affiliates and other carefully selected third parties now or in the future, e.g. joint venture partners, and/or service providers, who may be located overseas, to achieve the CCTV Monitoring Purposes. Third parties to which we may disclose CCTV Data and other Personal Data concerning you include: our affiliates, governmental and/or regulatory organizations, third-party service providers.</p>
            <p>We use reasonable organizational, technical, and administrative security measures to protect CCTV Data and Personal Data concerning you from accidental, unlawful, or unauthorized destruction, loss, access, use, alteration, or disclosure. We will from time-to-time review and update our security measures as necessary or upon development of the concerning technology to ensure effective and appropriate security measures, and in line with minimum legal requirements as prescribed by the relevant governmental organizations.</p>
            <p>If you believe that you have been recorded by our CCTV devices, you may have the rights pursuant to the laws. Anyone who believes that they have been filmed by the system can request a copy of the recording. Guests, staff or visitors should address any concerns or complaints over use of the our CCTV system to <u>nikitidream@gmail.com</u>.</p>

            <h2>What rights you have over your data</h2>
            <p>I. The right to be informed, announced and briefed about exercising your rights (Art. 12, 13, 14 GDPR), meaning your right to be informed on how your personal data are used (as it is thoroughly done in the present Briefing).</p>
            <p>II. The right to access the personal data that concern you and if Nikiti Dream Villas processes them, as a Data Controller (Art. 15 GDPR). Nikiti Dream Villas will provide a copy of the personal data after a relevant request is made from you.</p>
            <p>III. The right to rectify inaccurate data as well as to add data when they are incomplete (article 16 GDPR).</p>
            <p>IV. The right to erase your personal data (“The right to be forgotten”), subject to the obligations and legal rights of Nikiti Dream Villas over their preservation according to the current legislative and regulatory provisions (Art. 17 GDPR).</p>
            <p>V. The right to restrict the processing of your personal data if, either their accuracy is doubted, or the processing is illegal, or they lack the purpose of processing but their erasure is not applicable (Art. 18 GDPR).</p>
            <p>VI. The right to transfer your personal data to another Data Controller (data portability), if the processing is based on you consent and is conducted with automated means or to execute the contract between us (Art. 20 GDPR).</p>
            <p>VII. The right to object for reasons that concern your special condition in case your data are being processed for purposes of Nikiti Dream Villas’ legitimate interest (Art. 21 GDPR) and especially to object to the automated decision-making (Art. 22 GDPR).</p>
            <p>VIII. The right to withdraw the already given consent (article 7 GDPR) at any time, for processing conducted based on the consent. The legitimacy of the processing of your data is not influenced by the withdrawal of your consent up to the point you requested the withdrawal.</p>
            <p>IX. The right to lodge a complaint with the competent supervisory authority, that is to say the Hellenic Data Protection Authority (1 – 3 Kifisias Avenue, 115 23, Athens, +30 2106475600, <u>contact@dpa.gr</u>).</p>
            
            <h2>User liability</h2>
            <p>The user certifies that they are over the age of eighteen (18) and that the information they provide is accurate, specific, full, and up to date. They are responsible for any misleading or incorrect information they present on the Website, as well as any direct or indirect harm they cause Nikiti Dream Villas or our third parties.</p>
            
            <h2>Our statements</h2>
            <p>By using our platform, you agree to use any information about any other user in a manner consistent with the Terms of Service and the Privacy Policy.</p>
            <p>The user of our Website guarantees that the provided information is correct and accurate and is committed to disclose any changes or modifications thereof.</p>
            <p>We are not responsible for any damage (direct, indirect, positive, deponent) that may be caused to the visitor on account of the Website or its use. The visitor is solely responsible for the protection of their system against viruses.</p>
            <p>We do not make decisions or proceed to profiling based on an automated processing of your data.</p>
            <p>Our policy is likely to be amended / updated at any time. You will be informed about all the significant changes, while, every single time, the updated version will be posted on the page.</p>
            <p>We declare that will not process the user’s/visitor’s personal data for any purpose not mentioned herein, without prior notice and, where required, his consent.</p>
            <p>We respect and esteem the users of our Website and their privacy.</p>
            <p>If you have any questions, comments or complaints about our privacy practices, or if you want to update, delete or change any personal information, you can email us at <u>nikitidream@gmail.com</u>.</p>
            
            <h2>Effective date:  21.12.2023</h2>
            </div>
        </div>
    )
}