import axios from 'axios'
import { ContentRouts } from "../routes";
import { Config } from "../config";

export const getContent = async () => {
    try {
        return await axios.get(`${Config.baseURL}${ContentRouts.GET_CONTENT}`)
    } catch (error) {
        throw error
    }
}

export const changeImage = async (oldImageName: string, pageFileName: string, imageFile: any) => {
    try {
        const formData = new FormData()
        formData.append('imageFile', imageFile)

        return await axios.post(`${Config.baseURL}${ContentRouts.CHANGE_IMAGE}${oldImageName}/${pageFileName}`, formData,
            {
                withCredentials: true
            })
    } catch (error) {
        throw error
    }
}

export const setHomePage = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${ContentRouts.SET_HOME_PAGE}`, data)
    } catch (error) {
        throw error
    }
}

export const setAccommodationPage = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${ContentRouts.SET_ACCOMMODATION_PAGE}`, data)
    } catch (error) {
        throw error
    }
}

export const setExperiencesPage = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${ContentRouts.SET_EXPERIENCES_PAGE}`, data)
    } catch (error) {
        throw error
    }
}

export const setFacilitiesPage = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${ContentRouts.SET_FACILITIES_PAGE}`, data)
    } catch (error) {
        throw error
    }
}

export const setContactPage = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${ContentRouts.SET_CONTACT_PAGE}`, data)
    } catch (error) {
        throw error
    }
}