import axios from 'axios'
import { BeachRouts } from "../routes";
import { Config } from "../config";

export const getBeaches = async () => {
    try {
        return await axios.post(`${Config.baseURL}${BeachRouts.GET}`)
    } catch (error) {
        throw error
    }
}

export const addBeachImage = async (id: number, imageFile: any) => {
    try {
        const formData = new FormData()
        formData.append('imageFile', imageFile)

        return await axios.post(`${Config.baseURL}${BeachRouts.ADD_IMAGE}${id}`, formData,
            {
                withCredentials: true
            })
    } catch (error) {
        throw error
    }
}

export const addBeach = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${BeachRouts.CREATE}`, data)
    } catch (error) {
        throw error
    }
}

export const updateBeach = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${BeachRouts.UPDATE}`, data)
    } catch (error) {
        throw error
    }
}

export const deleteBeach = async (id: any) => {
    try {
        return await axios.delete(`${Config.baseURL}${BeachRouts.DELETE}${id}`)
    } catch (error) {
        throw error
    }
}

export const deleteBeachImage = async (imageName: string, id: number) => {
    try {
        return await axios.post(`${Config.baseURL}${BeachRouts.DELETE_IMAGE}${imageName}/${id}`)
    } catch (error) {
        throw error
    }
}