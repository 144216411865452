import { Grid } from "@mui/material";
import PrimaryButton from "../bottons/primary-button";
import { useDispatch } from "react-redux";
import Actions from "../../store/actions";
import Api from "../../api";
import { useState } from "react";
import { Feature } from "../../models/Feature";

interface Props {
    callback: Function,
    houseId: number
}

export default function FeatureCreator({ callback, houseId }: Props) {

    const dispatch = useDispatch()
    const [name, setName] = useState<string>("")

    const createFeature = async () => {
        if (name !== "") {
            setName("")
            dispatch(Actions.startLoadingHouseSession())
            await Api.addFeature({
                id: 0,
                name: name,
                houseId: houseId,
                house: null
            })
                .then(response => {
                    callback((prev: Feature[]) => [...prev, response.data])
                    dispatch(Actions.endLoadingHouseSession(""))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(Actions.endLoadingHouseSession("ERROR"))
                })
        }
    }

    return (
        <Grid container xs={12} sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
            <Grid item xs={12}>
                <input style={{width: "100%", boxSizing: "border-box"}} type="text" value={name} onChange={(e) => { setName(e.target.value) }} className="admin-input" />
            </Grid>
            <Grid item>
                <PrimaryButton onClick={createFeature}>Create new feature</PrimaryButton>
            </Grid>
        </Grid>
    )
}