import axios from 'axios'
import { GalleryRouts } from "../routes";
import { Config } from "../config";

export const getGalleryImages = async () => {
    try {
        return await axios.get(`${Config.baseURL}${GalleryRouts.GET_IMAGES}`)
    } catch (error) {
        throw error
    }
}

export const addGalleryImage = async (imageFile: any) => {
    try {
        const formData = new FormData()
        formData.append('imageFile', imageFile)
        return await axios.post(`${Config.baseURL}${GalleryRouts.CREATE_IMAGE}`, formData,
        {
            withCredentials: true
        })
    } catch (error) {
        throw error
    }
}

export const deleteGalleryImage = async (imageName: string) => {
    try {
        return await axios.post(`${Config.baseURL}${GalleryRouts.DELETE_IMAGE}${imageName}`)
    } catch (error) {
        throw error
    }
}