export function TermsAndConditions(){
    return(
        <div
            style={{display:'flex', alignItems: 'center' , flexDirection: 'column', paddingTop: '100px'}}
            >
            <div style={{width: '70%'}}>
                <h3>General terms and conditions for the rental of the holiday properties of Nikiti Dream Villas</h3>

                <p>In this Terms and Conditions <strong>“Nikiti Dream Villas”, “we”, “our”, or “us”</strong> mean: 5M Greece Single Member P.C., located in Nikiti, Greece, tax number: 801969527 with the tax office in Poligiros, with General Commercial Registry Number: 167266806000. Our website is at: <a href='https://nikitidream.com'>https://nikitidream.com</a> (hereinafter referred to as <strong>Website</strong>)</p>
                <p>These General Terms and Conditions of Service (“Terms”) describe your rights and responsibilities when using our accommodations and our online workplace productivity tools and platform (“Services”).</p>
                <p>If you start using or accessing our services (“Services”) or any of the services of Nikiti Dream Villas, you (“you” or “your”) are agreeing to these Terms (“Terms” or “Agreement”) and the Privacy Policy. Please read these Terms carefully before using this website.</p>
                <p>If you disagree with any of these Terms or the Privacy Policy, please do not use our Services.</p>
                <p>Privacy Policy and Data Protection</p>
                <p>Nikiti Dream Villas pays special attention to security and respect the privacy and confidentiality of your personal data. We have developed our <u>Privacy Policy</u> (also available on our Website) to fully inform you about how personal data and your information will be used by us. These Terms explicitly incorporate the Privacy Policy.</p>
                
                <h2>§1 Validity of the General Terms and Conditions (GTC)</h2>
                <p>(1) The following General Terms and Conditions apply to contracts for the rental of vacation villas as well as all other services and deliveries provided to the guest by Nikiti Dream Villas. The services of Nikiti Dream Villas are provided exclusively on the basis of these General Terms and Conditions.</p>
                <p>(2) Subletting or re-letting of the accommodations provided by Nikiti Dream Villas as well as their use for other than residential purposes are strictly prohibited.</p>
                <p>(3) Any terms and conditions of the guest shall only apply if these have been agreed in writing in advance. Alternative arrangements are only effective if they have been confirmed in writing in advance by Nikiti Dream Villas.</p>

                <h2>§2 Booking</h2>
                <p>Please submit booking requests via our website or the booking platforms BOOKING.COM and AIRBNB. Alternatively, you can send us a booking request at nikitidream@gmail.com, call us at +31642550452 or book directly using our booking engine.</p>
                <p>If the villa is available during your requested dates, you will receive a written booking confirmation from Nikiti Dream Villas as well as the invoice per e-mail. Your reservation is legally binding upon receipt of the booking confirmation and after payment has been made (see §3).</p>
               
                <h2>§3 Terms of payment</h2>
                <p>If you are booking outside of the booking platforms listed above, the total amount of the invoice must be transferred to Nikiti Dream Villas account at the latest 7 days before arrival. For short-term bookings within 7 days before arrival, 100% of the total amount is to be transferred to Nikiti Dream Villas account immediately after booking confirmation and receipt of invoice.</p>
                <p>Should you book a non refundable rate, your credit card may be charged any time.</p>
                <p>In the event of late payment, we are entitled to cancel the reservation and rent the holiday home to another party. Payment costs, in particular for bank transfers from abroad, are to be borne by the customer. Any bank transfer fees are to be paid in full by the customer. The full invoice amount is to be credited to our bank account without deduction of any fees listed above.</p>
                <p>We only accept payments via bank transfer and credit cards.</p>

                <h2>§4 Arrival and departure</h2>

                <p>The holiday home is available from 15:00 on the day of arrival. Arrival after 20:00 hrs must be arranged in advance as staff may be unavailable. The keys will be handed over at check-in. Check in prior to 15:00 can only be arranged in exceptional circumstances.</p>
                <p>On the day of departure, check-out must take place by 10:00 a.m. at the latest. Staff will be available for the check out and to hand over the keys. In the event of a significantly late departure, Nikiti Dream Villas reserves the right to charge the tenant a reasonable fee.</p>

                <h2>§5 Holiday properties, damages & deposit</h2>
                <p>All villas are handed over by Nikiti Dream Villas in a clean and tidy condition with a complete inventory. Nikiti Dream Villas must be informed immediately of any damages, defects or problems that arise during the rental period. Liability and costs for damage caused by the customer to the rented property including missing items e.g. broken crockery, damage to the floor or furniture, missing linens, shall be borne in full by the tenant. This also includes the costs for lost keys.</p>
                <p>The items listed in the inventory are to be treated according to their purpose, carefully and with care and are only intended to remain in the holiday homes or on the grounds of Nikiti Dream Villas. The moving of furnishings, in particular beds, is prohibited. The tenant is also liable for the fault of his fellow travelers. Damage caused by force majeure is excluded from this. In the event of use of the holiday home contrary to the terms of the contract, such as overcrowding, subletting, disregard and disturbance of the peace of the house, etc., as well as in the event of non-payment of the full rental price, Nikiti Dream Villas is entitled to terminate the contract without notice. Any rent already paid shall remain with Nikiti Dream Villas.</p>
                <p>If the customer holds a liability insurance, the damage must be reported to the insurance company by the customer. Nikiti Dream Villas must be informed of the name and address as well as the insurance number of the insurance company.</p>
                <p>Nikiti Dream Villas is entitled to charge reasonable damages as a holding deposit to the customer’s credit card. After repair or, if applicable, replacement for the damage caused, the full costs will be charged against the holding deposit. If the price exceeds the deposit amount, additional charges will be sought.</p>

                <h2>§6 Pets</h2>

                <p>Pets of any kind are not permitted in Nikiti Dream Villas. If animals are found to be in the property Nikiti Dream Villas is entitled to terminate the contract without notice. Any rent already paid shall remain with Nikiti Dream Villas and you will not receive a refund of the remainder of your booking amount. Any necessary professional cleaning or repair of damage caused by pets will be charged to the customer.</p>

                <h2>§7 Residence</h2>

                <p>The use of the holiday home is only permitted for the persons listed in the booking. Should the holiday home be used by more persons than agreed without prior notice, a separate fee must be paid for these persons, which is determined in the rental price. In addition, Nikiti Dream Villas has the right to terminate the rental contract without notice in this case.</p>
                <p>Subletting or transferring the holiday home to third parties is not permitted. It is not permitted to pass on the rental contract to third parties.</p>
                <p>The customer agrees to the general terms and conditions as well as the rules of complex of Nikiti Dream Villas. The declaration of consent is made with the payment of the rental fee by the customer.</p>
                <p>Violations of the General Terms and Conditions or the house rules of Nikiti Dream Villas entitle Nikiti Dream Villas to terminate the rental relationship immediately and without notice. There is no legal claim to repayment of the rent or compensation.</p>

                <h2>§8 Cancellation of booking by the tenant</h2>

                <p>In the event of cancellation of the rental contract, the customer is obliged to fully pay the agreed price if this takes place within 14 days before the planned arrival. Cancellation must be made in written form. The amount depends on the time until the day of arrival and is calculated as follows:
                <br/> · No fee to be paid up to 15 days before the date of arrival
                <br/> · 100% of the agreed price up to 14 days before the day of arrival</p>

                <h2>§9 Cancellation of booking by Nikiti Dream Villas</h2>

                <p>In the event of cancellation by Nikiti Dream Villas, as a result of force majeure or other unforeseeable circumstances (such as illness or accident of the host), as well as other circumstances for which Nikiti Dream Villas is not responsible making fulfilment impossible, liability is limited only to reimbursement of costs. In the event of a justified cancellation by Nikiti Dream Villas, the customer shall have no claim to compensation – no liability shall be assumed for travel and hotel costs.</p>
                <p>Nikiti Dream Villas is entitled to withdraw from the contract without notice after the start of the rental period if the tenant continues to disturb other tenants on a sustained basis despite a warning or if the tenant behaves in a manner that breaches the contract to such an extent that the immediate cancellation of the rental contract is necessary.</p>

                <h2>§10 Liability of the landlord</h2>

                <p>Nikiti Dream Villas is liable within the scope of normal duty of care agreements. Possible breakdowns to appliances as a result of force majeure or disruptions in the water or electricity supply are hereby excluded.</p>

                <h2>§11 Internet access from Nikiti Dream Villas via WLAN / Wi-Fi</h2>

                <p>Nikiti Dream Villas has internet access via WLAN / Wi-Fi in its holiday property. We permit use of the WLAN / Wi-Fi access to the Internet for the duration of the tenant’s stay in the holiday property. The tenant is not entitled to allow third parties to use the WLAN / Wi-Fi.</p>
                <p>Nikiti Dream Villas does not undertake to guarantee the actual availability, reliability or suitability of the Internet access for any purpose. We are entitled at any time to allow other users to use the WLAN / Wi-Fi in whole, in part or temporarily and to restrict or completely exclude the tenant’s access in whole, in part or temporarily if the connection has been or is being used in an illegal manner, insofar as the landlord must fear a claim on account of this and cannot prevent this with a common and reasonable or appropriate expenditure of time. In particular, Nikiti Dream Villas reserves the right, at its reasonable discretion and at any time, to block access to certain pages or services via the WLAN / Wi-Fi (e.g. pornographic or chargeable pages).</p>
                <p>The use of the website is secured by means of access protection. It is expressly forbidden to pass on access data such as login and password to third parties. Nikiti Dream Villas reserves the right to change access data.</p>
                <p>We would like to point out to our tenants that Nikiti Dream Villas only provides access to the Internet via WLAN / Wi-Fi. A firewall or virus protection is not provided. All dangers, risks, damage to end devices due to malware or costs due to visiting an Internet site for which a charge is made as well as costs for conducting legally binding transactions resulting from the use of the Internet are to be borne in full by the tenant.</p>
                <p>Visiting immoral or illegal internet sites as well as transmitting or downloading data from said sites will not be tolerated. In the event of an illegal visit to such a site, Nikiti Dream Villas reserves the right to block access to the internet.</p>

                <h2>§12 House Rules</h2>

                <p>1. Compliance with the house rules is obligatory for the tenant and all accompanying persons.</p>
                <p>2. Quiet hours are from 11.00 p.m. to 8.00 a.m. and 14.00-16.00. TV and audio devices must be set to room volume level.</p>
                <p>3. The customer and/or parents are responsible for the constant supervision of children when using the pools. The use of the pools is not permitted during the night.</p>
                <p>4. Pets are not allowed on the premises or in the holiday homes of Nikiti Dream Villas.</p>
                <p>5. When leaving the holiday home, the customer is obliged to keep windows and doors closed and to switch off all electronic devices.</p>
                <p>6. Smoking is generally prohibited in all Nikiti Dream Villas holiday homes. Smoking is only permitted on balconies and terraces or in the outdoor facilities of Nikiti Dream Villas; the use of the ashtrays provided for this purpose is obligatory. In the event of contraventions, Nikiti Dream Villas reserves the right to charge a cleaning fee of up to 100.00 euros (net).</p>
                <p>7. Nikiti Dream Villas’ own car park offers free parking spaces. Please ensure that no vehicles obstruct the exit from the Nikiti Dream Villas grounds. Short-term parking at the holiday homes is permitted for unloading and loading the car, after which the designated parking spaces are to be used.</p>
                <p>8. In order to avoid damage to the buildings and out of consideration for the trees and shrubs, all ball sports are prohibited in the entrance area of the holiday homes. Ball games are only permitted in the area of the pools on the designated lawns. Please refer to the signposted site plan of Nikiti Dream Villas.</p>
                <p>9. The children’s playground may be used by children up to and including 14 years of age and is at their own risk. The use of the leisure and play facilities in the outdoor area is not subject to any age limit and is also at your own risk. Parents are liable for themselves and their children.</p>
                <p>10. Nikiti Dream Villas has right of access to the holiday home complex and the holiday homes at all times, in particular in the event of imminent danger. If circumstances allow, Nikiti Dream Villas will inform the customer of this before exercising the right of access.</p>

                <h2>§13 Our Intellectual Property Rights</h2>

                <p>1. All the content of our platform, including, but not limited to, templates, pictures, photographs, drawings, graphics, texts, slogans, etc. are the intellectual property of the controller and are protected by the relevant provisions of Greek and European law, as well as international contracts. Names, pictures, logos, and distinguishing marks representing controller and/or third parties are trademarks and distinguishing marks of the controller and/or third parties and are protected by Greece, Community and international laws on trademarks, industrial and intellectual property and unfair competition.</p>
                <p>2. You agree not to:
                <br/>a. change, translate, create or attempt to make derivative works or copies of the software or website, either in whole or in part;
                <br/>b. decompile, disassemble, convert the object code to source code,
                <br/>c. distribute, assign, share, lease, lend, sell, resell, rent, sublet or otherwise transfer the software or website for your benefit.</p>
                <p>3. You are not allowed to reproduce, redistribute, duplicate, copy, remodel, change, disclose, distribute, republish, download, show, take screenshots, upload or transmit the material available on our platform in any case and in any way; nor the sale, resale or assignment of the use of the platform and the services, without the express written permission by us.</p>
                <p>4. Your use of third-party software or content downloaded from our platform does not transfer to you rights, titles or interests. In addition, you agree that you will not use any third-party software or content available to you unless expressly allowed under the terms of service or third-party license. If you do not agree to the terms of service or the third-party license agreement, do not download or use the third-party software or content.</p>
                <p>5. In the event of infringement of the Intellectual Property Terms, you must immediately delete any material obtained illegally.</p>

                <h2>§14 Written form</h2>

                <p>There are no agreements other than those listed in this contract. No verbal agreements have been made. The general terms and conditions are accepted once the booking is being made.</p>

                <h2>§15 Limitation of Liability</h2>

                <p>1. You agree that you are solely responsible for all charges that may be incurred for accessing and using the website.</p>
                <p>2. You expressly understand and agree that we or our suppliers shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or in connection with the use of or inability to use the service (however arising including negligence).</p>
                <p>3. We bear no responsibility for any technical problems that may occur to users when they attempt to access our platform or during such access and are related to the operation or compatibility of their own infrastructure using the platform.</p>
                <p>4. We are not responsible for any loss or damage caused by a denial of service attack, viruses or other malware or hardware that may harm the user’s computer, accessories, data or any other material from the use of the platform or the uploading of material contained therein or similar material of another site referred to by the platform and in general for acts or omissions by third parties and in particular unauthorized interventions of third parties to products and/or services and/or information made available through the site.</p>
                <p>5. You are solely responsible for your conduct and the accuracy and appropriateness of all your data and content within your site, including: text, file, information, username, images, graphics, photos, profiles, audio, and video clips, sounds, musical works, copyrighted works, applications, links, exchange rates and currency conversion, sales tax calculations, terms of service, returns and refund, product pricing and description, shipping calculations and charges, contact information, email communications, payment processing and any other content or material you submit, upload or show on or through our platform and your account. You are solely responsible, and we will not be held responsible for inaccurate information and any potential damages caused by such inaccuracies. You further understand that we may not maintain copies of files or documents that are sent by you and that you are solely responsible for backing up all your data.</p>
                <p>6. We may provide access to the content of third-party websites for information purposes only. We do not control any of these third-party websites or services and are unaware of the availability, content, personal data, privacy policy, quality, and completeness, whether or not there are charges. You explicitly acknowledge and agree that our platform is not in any way responsible for such third-party websites or web services. Your interaction in any way with these third-party sites or web services is at your sole and absolute discretion and we are not responsible in that respect.</p>
                <p>7. We are not in any way liable to you or third parties for any loss or damage of any kind directly or indirectly related to it, its content, services or any of its applications or intellectual property rights, delay in the operation or transmission, or any kind of malfunction.</p>
                <p>8. We are not liable to you or third parties for any loss or damage of any kind related to force majeure events and violation of legislation (eg. tax, data protection etc), related to the use of our platform.</p>
                <p>9. We do not accept any liability for losses, damages, or loss of revenue that may be caused by the blocking of our website due to incomplete or delayed payment, as long as the failure or delay can be attributed to the user. </p>
                <p>10. For the avoidance of doubt, we do not act on your behalf, nor does it establish any partnership or consortium between us.</p>
                <p>11. The linked websites are not controlled by us and, for this reason, we are not responsible for the content of these links, nor for the links on linked websites or any changes or updates to these websites.</p>

                <h2>§16 Severability clause</h2>

                <p>Should one or more provisions of these General Terms and Conditions become invalid, this shall not affect the validity of the remaining provisions. The invalid provision shall be replaced by a valid provision which comes as close as possible to the economic purpose pursued by the invalid provision.</p>

                <h2>§17 Termination</h2>

                <p>1. We may terminate Services at any time, without penalty and without notice, if you fail to comply with any of the terms of this Agreement or the intellectual property protections applicable to these Services. Verbal or written abuse of any kind (including threats of abuse or retribution) of any customer, employee, member, or officer will also result in immediate account termination. Violation of any of these policies may result in user information tracking with such information being stored to identify the offending user. Offending users may be permanently restricted from holding an account or using the Services.</p>
                <p>2. Services may be terminated by you, without cause, by following the cancellation procedures set forth in your account settings. Once cancellation is confirmed your website will be taken offline immediately, your account will be closed as well and you won’t be able to log in anymore. When your subscription is cancelled, your account and its content is marked for permanent deletion after thirty (30) days. All of your content will be deleted within the next three (3) months from the Services. In that period, you are able to recover your account if it’s not already deleted. Additionally, a fixed sum can be charged for the cost of recovering a late commercial payment.</p>
                <p>3. Without limiting any other remedies, we may suspend or terminate your account and erase your financial data if we suspect that you (by conviction, settlement, insurance or escrow investigation, or otherwise) have engaged in illegal or fraudulent activity in connection with our Website.</p>

                <h2>§18 Social media platforms</h2>

                <p>We also maintain presences on social media platforms including Instagram and Facebook. Any services provided by Nikiti Dream Villas through its social media platforms and any information that is disclosed, submitted or offered through its social media accounts are subject to these Terms, unless agreed otherwise, expressly and in writing.</p>

                <h2>§19 Modifications to the Terms</h2>

                <p>1.	These Terms, together with any other expressly referenced document, constitute the complete agreement between us and the users in relation to the use of our Services, replacing any prior written or oral agreement, conciliation or settlement between us.</p>
                <p>2.	We reserve the right to unilaterally modify, renew or cancel any term contained in these Terms, at any time and without sending prior notice to users.</p>
                <p>3.	We shall inform users of any modifications and changes, via the platform or email or as otherwise required by law.</p>
                <p>4.	Modifications and changes will never apply retroactively. By continuing to use or access our Services after any modifications come into effect, you agree to be bound by the modified Terms. If you disagree with our changes, then you should stop using our Services.</p>
                <p>5.	It is your responsibility to read the Terms and our Privacy Policy at regular intervals, as these are applicable and in force at the time you use the platform.</p>

                <h2>§20 CCTV Systems</h2>

                <p>This section sets out the appropriate actions and procedures which Nikiti Dream Villas follows in respect of the use of Closed Circuit Television surveillance systems (CCTV Systems) at our premises.</p>
                <p>Please note that the entrance, parking, barbeque and storage areas are monitored by CCTV 24 hours a day. Nikiti Dream Villas reserves the right for its employees and contractors to review footage as required and by making a reservation and entering onto our sites you consent to your image being recorded and reviewed. Recorded CCTV footage will be stored securely and retained in compliance with relevant laws.</p>
                <p>The purpose of the use of the CCTV Systems and the collection and processing of CCTV images is for the prevention or detection of crime or disorder, apprehension and prosecution of offenders (including use of images as evidence in criminal proceedings), and the protection of the Our property and assets.</p>
                <p>Prior to any camera installation, we ensure that the installation complies with this policy and that the use of any camera is justified, necessary and proportionate. Our Data Protection Officer  will regularly assess whether the use of any camera and the CCTV System as a whole continues to be justified, necessary and proportionate.</p>
                <p>The images captured by the CCTV is retained for a maximum of one year from the date of recording.</p>
                <p>For more information read our Privacy Policy.</p>

                <h2>§21 Jurisdiction</h2>

                <p>The civil court of Thessaloniki is responsible for any disputes arising from the contractual relationship.</p>

                <h2>Released on: 21.12.2023</h2>

            </div>
         </div>
    )
}