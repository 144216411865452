import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
//import Toolbar from '@mui/material/Toolbar';
//import Typography from '@mui/material/Typography';
import Button, { ButtonProps } from "@mui/material/Button";
//import IconButton from '@mui/material/IconButton';
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material/styles";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import blackLogoImg from "../../../assets/images/logo/vectorpaint-black.svg";
import { Logout } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../store/actions";
import Api from "../../../api";
import { useCookies } from "react-cookie";
const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "white",
  backgroundColor: "rgb(0,157,255)",
  borderRadius: "0px",
  padding: "10px 30px",

  "&:hover": {
    backgroundColor: "white",
    color: "rgb(0,157,255)",
  },
}));

interface ContentData {
  [key: string]: string;
}

function AdminApplicationBar() {
  const dispatch = useDispatch();

  const contentSession = useSelector((state: any) => state.contentSession);

  const [contentData, setContentData] = React.useState<ContentData | null>(
    null
  );

  React.useEffect(() => {
    if (contentSession.contact !== null) setContentData(contentSession.contact);
  }, [contentSession.contact]);

  const [state, setState] = React.useState(false);
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(["ndToken"]);

  const handleLogout = async () => {
    dispatch(Actions.startLoadingUserSession());
    await Api.userLogout()
      .then((response) => {
        dispatch(Actions.setUserData(""));
        removeCookie("ndToken");
        navigate("/");
        dispatch(Actions.endLoadingUserSession(""));
      })
      .catch((error) => {
        console.log(error);
        dispatch(Actions.endLoadingUserSession("ERROR"));
      });
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState(open);
    };

  const list = () => (
    <Box
      sx={{ minWidth: "250px" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: { xs: "70px", md: "110px" },
        }}
      >
        <Link className="app-bar-link" to="/">
          <img src={blackLogoImg} style={{ width: "90px" }} />
        </Link>
      </Grid>
      <List>
        {[
          "Home",
          "Accommodation",
          "Facilities",
          "Experiences",
          "Gallery",
          "Contact us",
          "Booking",
          "Create house",
        ].map((text, index) => (
          <Link
            key={text}
            className="app-bar-link"
            to={"/" + (text !== "Home" ? text.toLowerCase() : "")}
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={text} sx={{ padding: "0px 20px" }} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <Box sx={{ marginTop: "50px" }}>
        {contentData !== null && (
          <>
            <Box
              sx={{
                padding: "12px 36px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <PhoneAndroidIcon fontSize="small" /> {contentData.phone}
            </Box>
            <Box
              sx={{
                padding: "12px 36px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <EmailIcon fontSize="small" /> {contentData.email}
            </Box>
          </>
        )}
        <Box
          onClick={handleLogout}
          sx={{
            padding: "12px 36px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Logout fontSize="small" /> Logout
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "black",
            boxShadow: "none",
            height: { xs: "70px" },
            top: 0,
          }}
        >
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid
              item
              xs={6}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <MenuIcon
                sx={{ color: "white", margin: "20px" }}
                onClick={toggleDrawer(true)}
              />
            </Grid>
          </Grid>
        </AppBar>
      </Box>
      <SwipeableDrawer
        anchor={"left"}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </>
  );
}

export default AdminApplicationBar;
