import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button, { ButtonProps } from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material/styles";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TikTokIcon from "../../assets/images/tiktoktransparent.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import whiteLogoImg from "../../assets/images/logo/vectorpaint.svg";
import blackLogoImg from "../../assets/images/logo/vectorpaint-black.svg";
import { useSelector } from "react-redux";
import { RoomRaccoonBasicUrl, TikTokUrl } from "../../models/constants/Links";
import { MainThemeColor } from "../../models/constants/Colors";

export const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "white",
  backgroundColor: MainThemeColor,
  borderRadius: "0px",
  padding: "10px 30px",

  "&:hover": {
    backgroundColor: "white",
    color: MainThemeColor,
  },
}));

interface ContentData {
  [key: string]: string;
}

function ApplicationBar() {
  const contentSession = useSelector((state: any) => state.contentSession);

  const [contentData, setContentData] = React.useState<ContentData | null>(
    null
  );

  React.useEffect(() => {
    if (contentSession.contact !== null) setContentData(contentSession.contact);
  }, [contentSession.contact]);

  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  const drawerContent = () => (
    <Box
      sx={{ minWidth: "250px" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: { xs: "70px", md: "110px" },
        }}
      >
        <Link className="app-bar-link" to="/">
          <img src={blackLogoImg} style={{ width: "90px" }} />
        </Link>
      </Grid>
      <List>
        {[
          "Accommodation",
          "Facilities",
          "Experiences",
          "Gallery",
          "Contact Us",
        ].map((text, index) => (
          <Link
            key={text}
            className="app-bar-link"
            to={"/" + text.toLowerCase()}
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={text} sx={{ padding: "0px 20px" }} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <Box sx={{ marginTop: "50px" }}>
        <Box sx={{ padding: "12px 36px" }}>
          <b>Contact us</b>
        </Box>
        {contentData !== null && (
          <>
            <Box
              sx={{
                padding: "12px 36px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <PhoneAndroidIcon fontSize="small" /> {contentData.phone}
            </Box>
            <Box
              sx={{
                padding: "12px 36px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <EmailIcon fontSize="small" /> {contentData.email}
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ marginTop: "50px" }}>
        <Box sx={{ padding: "12px 36px" }}>
          <b>Find us</b>
        </Box>
        <Box
          sx={{
            padding: "12px 36px",
            gap: "4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {contentData !== null && (
            <>
              <a
                href={contentData.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="app-bar-link"
              >
                <InstagramIcon fontSize="large" />
              </a>
              <a
                href={contentData.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="app-bar-link"
              >
                <FacebookIcon fontSize="large" />
              </a>
              <a
                href={TikTokUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="app-bar-link"
              >
                <img
                  style={{ height: "32px", width: "32px" }}
                  src={TikTokIcon}
                  alt=""
                />
              </a>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );

  function scrollFunction() {
    var appBar = document.getElementById("app-bar") as HTMLDivElement | null;
    if (
      location.pathname.includes("/details") ||
      location.pathname.includes("/gallery") ||
      location.pathname.includes("/termsandconditions") ||
      location.pathname.includes("/privacypolicy") ||
      location.pathname.includes("/booking")
    ) {
      if (appBar !== null) {
        appBar.style.transition = ".5s";
        appBar.style.background = "black";
        appBar.style.boxShadow =
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)";
        //appBar.style.borderBottom = "1px solid rgb(244,255,158)";
      }
    } else {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        if (appBar !== null) {
          appBar.style.transition = ".5s";
          appBar.style.background = "black";
          appBar.style.boxShadow =
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)";
          //appBar.style.borderBottom = "1px solid rgb(244,255,158)";
        }
      } else {
        if (appBar !== null) {
          appBar.style.transition = ".5s";
          appBar.style.background = "transparent";
          appBar.style.boxShadow = "none";
          //appBar.style.borderBottom = "1px solid rgb(244,255,158)";
        }
      }
    }
  }
  const [isRendered, setIsRendered] = React.useState(false);
  React.useEffect(() => {
    setIsRendered(true);
    if (
      location.pathname.includes("/details") ||
      location.pathname.includes("/gallery") ||
      location.pathname.includes("/termsandconditions") ||
      location.pathname.includes("/privacypolicy") ||
      location.pathname.includes("/booking")
    ) {
      var appBar = document.getElementById("app-bar") as HTMLDivElement | null;
      if (appBar !== null) {
        appBar.style.background = "black";
        appBar.style.boxShadow =
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)";
      }
    }
    if (isRendered)
      window.onscroll = function () {
        scrollFunction();
      };
  }, [isRendered, location.pathname]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          id="app-bar"
          position="fixed"
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            height: { xs: "auto", md: "110px" },
            top: 0,
          }}
        >
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid
              item
              xs={6}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <MenuIcon
                fontSize="large"
                sx={{ color: "white", margin: "20px" }}
                onClick={toggleDrawer(true)}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                height: { xs: "70px", md: "110px" },
              }}
            >
              <Link className="app-bar-link" to="/">
                <img src={whiteLogoImg} style={{ width: "90px" }} />
              </Link>
              <Box
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  fontSize: 25,
                  marginTop: "5px",
                  height: "25px",
                  padding: "5px 8px ",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Nikiti Dream Villas
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <a
                href={RoomRaccoonBasicUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="app-bar-link"
              >
                <ColorButton color="secondary" variant="contained">
                  Book now
                </ColorButton>
              </a>
            </Grid>
          </Grid>
        </AppBar>
      </Box>
      <SwipeableDrawer
        anchor={"left"}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {drawerContent()}
      </SwipeableDrawer>
    </>
  );
}

export default ApplicationBar;
