import { useState, useEffect, ChangeEvent } from "react";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../components/bottons/primary-button";
import Actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../api";
import LoadingModal from "../../components/modals/loading-modal/loading-modal";
import { Image } from "../../models/Image";
import { Config } from "../../api/config";
import HouseImageUploader from "../../components/house-image-uploader/house-image-uploader";
import ImageCard from "../../components/cards/image-card/image-card";
import { Amenity } from "../../models/Amenity";
import AmenityCard from "../../components/cards/amenity-card/amenity-card";
import AmenityCreator from "../../components/amenity-creator/amenity-creator";
import { Feature } from "../../models/Feature";
import FeatureCreator from "../../components/feature-creator/feature-creator";
import FeatureCard from "../../components/cards/feature-card/feature-card";
import { useNavigate } from "react-router-dom";

interface TranslationData {
    [key: string]: string;
}

const steps = ['Name and descriptions', 'Add images', 'Amenities and features']

export default function CreateHouse() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const houseSession = useSelector((state: any) => state.houseSession)
    const amenitySession = useSelector((state: any) => state.amenitySession)
    const [step, setStep] = useState(0)

    const [translationData, setTranslationData] = useState<TranslationData>({
        name: "",
        shortDescription: "",
        longDescription: ""
    })

    const [houseId, setHouseId] = useState<number | null>(null)
    const [images, setImages] = useState<Image[]>([])
    const [amenities, setAmenities] = useState<Amenity[]>([])
    const [features, setFeatures] = useState<Feature[]>([])

    const inputChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTranslationData((prev: TranslationData | null) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const createHouse = async () => {
        if (translationData.name === "" && translationData.shortDescription === "" && translationData.longDescription === "")
            return
        dispatch(Actions.startLoadingHouseSession())
        await Api.addHouse({
            isActive: false
        })
            .then(response => {
                console.log(response)
                setHouseId(response.data.id)
                createTranslation(response.data.id)
            })
            .catch(error => {
                console.log(error)
                dispatch(Actions.endLoadingHouseSession("ERROR"))
            })
    }

    const createTranslation = async (houseId: number) => {
        await Api.addHouseTranslation({
            id: 0,
            name: translationData.name,
            shortDescription: translationData.shortDescription,
            longDescription: translationData.longDescription,
            languageId: 1,
            houseId: houseId,
            language: null,
            house: null
          })
            .then(response => {
                setStep(1)
                console.log(response)
                dispatch(Actions.endLoadingHouseSession(""))
            })
            .catch(error => {
                console.log(error)
                dispatch(Actions.endLoadingHouseSession("ERROR"))
            })
    }

    const getAmenities = async () => {
        dispatch(Actions.startLoadingAmenitySession())
        await Api.getAmenities()
            .then(response => {
                setStep(2)
                dispatch(Actions.setAmenities(response.data))
                dispatch(Actions.endLoadingAmenitySession(""))
            })
            .catch(error => {
                console.log(error)
                dispatch(Actions.endLoadingAmenitySession("ERROR"))
            })
    }

    const activateHouse = async () => {
        if(houseId !== null){
        dispatch(Actions.startLoadingHouseSession())
        await Api.updateHouse(houseId)
            .then(response => {
                dispatch(Actions.endLoadingHouseSession(""))
                GetHouses()

            })
            .catch(error => {
                console.log(error)
                dispatch(Actions.endLoadingHouseSession("ERROR"))
            })
        }
    }

    const GetHouses = async () => {
        dispatch(Actions.startLoadingHouseSession())
        await Api.getHouses()
          .then(response => {
            //console.log(response.data)
            dispatch(Actions.setAllHouses(response.data))
            dispatch(Actions.endLoadingHouseSession(""))
            navigate('/edit accommodation')
          })
          .catch(err => {
            console.log(err)
            dispatch(Actions.setAllHouses([]))
            dispatch(Actions.endLoadingHouseSession("ERROR"))
            /*if(err.message === 'Network Error')
              navigate('/error')*/
          })
      }


    useEffect(() => {
        console.log(translationData)
    }, [translationData])

    return (
        <>
            <LoadingModal open={houseSession.loading || amenitySession.loading} />
            <Grid container sx={{}}>
                <Grid container sx={{ textAlign: "center", display: "flex", justifyContent: "center", margin: "100px 0px 50px 0px", alignItems: "center" }}>
                    <Typography variant='h5' sx={{ fontWeight: "bold" }}>
                        Create new house
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{}}>
                <Grid container sx={{ textAlign: "center", display: "flex", justifyContent: "center", margin: "0px 0px 50px 0px", alignItems: "center" }}>
                    <Typography variant='h6' sx={{ fontWeight: "bold" }}>
                        {steps[step]}
                    </Typography>
                </Grid>
            </Grid>
            {
                step === 0 &&
                <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item xs={12} lg={9} xl={7} sx={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <input className="admin-input" placeholder="Name" name='name' value={translationData.name} onChange={inputChangeHandler} />
                        <Typography variant='body1' sx={{ fontWeight: "bold", color: "orange", margin: "20px 10px 0px 10px" }}>
                            A short description will be displayed on the 'Accommodation' page!
                        </Typography>
                        <textarea className="admin-textarea" style={{ height: "250px" }} placeholder="Short description" name='shortDescription' value={translationData.shortDescription} onChange={inputChangeHandler} />
                        <Typography variant='body1' sx={{ fontWeight: "bold", color: "orange", margin: "20px 10px 0px 10px" }}>
                            A long description will be displayed on the 'Details' page in the 'Information' section!
                        </Typography>
                        <textarea className="admin-textarea" style={{ height: "250px" }} placeholder="Long description" name='longDescription' value={translationData.longDescription} onChange={inputChangeHandler} />
                    </Grid>
                    <Grid item xs={12} md={11} lg={9} xl={7} sx={{ position: "relative", margin: "50px 0px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <PrimaryButton onClick={createHouse}>Next</PrimaryButton>
                    </Grid>
                </Grid>
            }
            {
                step === 1 && houseId !== null &&
                <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item xs={12} lg={9} xl={7} sx={{ position: "relative", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                        {
                            images.length > 0 &&
                            images.map((image: Image) => {
                                return (
                                    <ImageCard key={image.smallImageName} image={image} setImages={setImages} />
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={12} md={11} lg={9} xl={7} sx={{ position: "relative", margin: "50px 0px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <HouseImageUploader setImages={setImages} houseId={houseId} />
                    </Grid>
                    <Grid item xs={12} md={11} lg={9} xl={7} sx={{ position: "relative", margin: "50px 0px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <PrimaryButton onClick={getAmenities}>Next</PrimaryButton>
                    </Grid>
                </Grid>
            }
            {
                step === 2 && houseId !== null &&
                <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item xs={12} lg={9} xl={7} sx={{ position: "relative", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant='body1' sx={{ fontWeight: "bold", color: "orange", margin: "20px 10px 0px 10px" }}>
                            Select amenities. A amenities will be displayed on the 'Details' page in the 'Amenities' section!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={9} xl={7} sx={{ position: "relative", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                        {
                            amenitySession.amenities.length > 0 &&
                            amenitySession.amenities.map((amenity: Amenity) => {
                                return (
                                    <AmenityCard key={amenity.id} houseId={houseId} data={amenity} setAmenities={setAmenities} selected={amenities.filter(x => x.id === amenity.id).length > 0} />
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={12} lg={9} xl={7} sx={{ position: "relative", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant='body1' sx={{ fontWeight: "bold", color: "orange", margin: "50px 10px 0px 10px" }}>
                            Create amenities.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={11} lg={9} xl={7} sx={{ position: "relative", margin: "0px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <AmenityCreator callback={getAmenities}/>
                    </Grid>
                    <Grid item xs={12} lg={9} xl={7} sx={{ position: "relative", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant='body1' sx={{ fontWeight: "bold", color: "orange", margin: "20px 10px 0px 10px" }}>
                            Select amenities. A amenities will be displayed on the 'Details' page in the 'Amenities' section!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={9} xl={7} sx={{ position: "relative", display: "flex", flexDirection: "column" }}>
                        {
                            features.length > 0 &&
                            features.map((feature: Feature) => {
                                return (
                                    <FeatureCard key={feature.id} data={feature} setFeatures={setFeatures} />
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={12} lg={9} xl={7} sx={{ position: "relative", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant='body1' sx={{ fontWeight: "bold", color: "orange", margin: "50px 10px 0px 10px" }}>
                            Create features.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={11} lg={9} xl={7} sx={{ position: "relative", margin: "0px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <FeatureCreator callback={setFeatures} houseId={houseId}/>
                    </Grid>
                    <Grid item xs={12} md={11} lg={9} xl={7} sx={{ position: "relative", margin: "50px 0px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <PrimaryButton onClick={activateHouse}>Finish</PrimaryButton>
                    </Grid>
                </Grid>
            }
        </>
    )
}