import { createSlice } from '@reduxjs/toolkit'

export const beachSlice = createSlice({
    name: 'beach',
    initialState: {
        beaches: [] as any[],
        loading: false,
        message: '',
        error: false
    },
    reducers: {
        startLoadingBeachSession: (state) => {
            return { ...state, loading: true }
        },
        endLoadingBeachSession: (state, action) => {
            return { ...state, loading: false, message: action.payload }
        },
        setBeaches: (state, action) => {
            return { ...state, beaches: action.payload }
        }
    }
})

export const { startLoadingBeachSession, endLoadingBeachSession, setBeaches } = beachSlice.actions

export default beachSlice.reducer