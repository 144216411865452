import axios from 'axios'
import { UserRouts } from "../routes";
import { Config } from "../config";

export const userLogin = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${UserRouts.LOG_IN}`, data)
    } catch (error) {
        throw error
    }
}

export const userLogout = async () => {
    try {
        const token = document.cookie
            .split("; ")
            .find((row) => row.startsWith("ndToken="))
            ?.split("=")[1];
        return await axios.post(`${Config.baseURL}${UserRouts.LOG_OUT}`, {}, {
            headers: {
                'Authorization': `bearer ${token}`
            }
        })
    } catch (error) {
        throw error
    }
}

export const getUser = async () => {
    try {
        return await axios.post(`${Config.baseURL}${UserRouts.GET_DATA}`)
    } catch (error) {
        throw error
    }
}