import axios from 'axios'
import { HouseRouts } from "../routes";
import { Config } from "../config";

export const addHouse = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${HouseRouts.ADD_HOUSE}`, {data})
    } catch (error) {
        throw new Error('Error rised while fetching houses...')
    }
}

export const getHouses = async () => {
    try {
        return await axios.post(`${Config.baseURL}${HouseRouts.GET_ALL_HOUSES}`, {},{
            headers: {"Access-Control-Allow-Origin": "*"}
        })
    } catch (error) {
        throw error
    }
}

export const getHouseById = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${HouseRouts.GET_HOUSE_BY_ID}`, {data})
    } catch (error) {
        throw new Error('Error rised while fetching house...')
    }
}

export const updateHouse = async (id: number) => {
    try {
        return await axios.post(`${Config.baseURL}${HouseRouts.UPDATE_HOUSE}${id}`)
    } catch (error) {
        throw error
    }
}

export const deleteHouse = async (data: any) => {
    try {
        return await axios.delete(`${Config.baseURL}${HouseRouts.DELETE_HOUSE}`, {data})
    } catch (error) {
        throw new Error('Error rised while deleting house...')
    }
}