import axios from 'axios'
import { AvailabilityRouts } from "../routes";
import { Config } from "../config";

export const updateAvailability = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${AvailabilityRouts.UPDATE_AVAILABILITY}`, {data})
    } catch (error) {
        throw new Error('Error rised while updating house...')
    }
}

export const deleteAvailability = async (data: any) => {
    try {
        return await axios.delete(`${Config.baseURL}${AvailabilityRouts.DELETE_AVAILABILITY}`, {data})
    } catch (error) {
        throw new Error('Error rised while deleting house...')
    }
}