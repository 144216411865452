import { createSlice } from '@reduxjs/toolkit'

export const reservationSlice = createSlice({
    name: 'reservation',
    initialState: {
        //availableAppointments:[] as [{houseId: number, year: number, month: number, unvailableDates: []}] | [],
        house: null,
        arrivalDate: null,
        departureDate: null,
        numberOfNights: 1,
        numberOfPersons: null,
        additions: [],
        price: null,
        reservationStep: 0,
        loadinReservationSteps: false,
        loading: false,
        message: '',
        error: false
    },
    reducers: {
        startLoadingReservationSession: (state) => {
            return { ...state, loading: true }
        },
        endLoadingReservationSession: (state, action) => {
            return { ...state, loading: false, message: action.payload }
        },
        startLoadingReservationSteps: (state) => {
            return { ...state, loadinReservationSteps: true }
        },
        endLoadingReservationSteps: (state, action) => {
            return { ...state, loadinReservationSteps: false, message: action.payload }
        },
        setReservationStep: (state, action) => {
            return { ...state, reservationStep: action.payload}
        },
        setHouse: (state, action) => {
            return { ...state, house: action.payload}
        },
        setArrivalDate: (state, action) => {
            return { ...state, arrivalDate: action.payload}
        },
        setDepartureDate: (state, action) => {
            return { ...state, departureDate: action.payload}
        },
        setNumberOfNights: (state, action) => {
            return { ...state, numberOfNights: action.payload}
        },
        setNumberOfPersons: (state, action) => {
            return { ...state, numberOfPersons: action.payload}
        },
        setPrice: (state, action) => {
            return { ...state, price: action.payload}
        },
        setAdditions: (state, action) => {
            return { ...state, additions: action.payload}
        },
        clearReservationSession: (state) => {
            return { ...state, arrivalDate: null, departureDate: null, loading: false, message: '', error: false, numberOfNights: 1, numberOfPersons: null, house: null, additions: []}
        },
    },
})

export const {setPrice, setReservationStep, startLoadingReservationSession, endLoadingReservationSession, startLoadingReservationSteps, endLoadingReservationSteps, setHouse, setArrivalDate, setDepartureDate, setNumberOfNights, setNumberOfPersons, setAdditions, clearReservationSession } = reservationSlice.actions

export default reservationSlice.reducer