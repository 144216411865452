import { createSlice } from '@reduxjs/toolkit'
import { Amenity } from '../../models/Amenity'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: "",
        loading: false,
        message: '',
        error: false
    },
    reducers: {
        startLoadingUserSession: (state) => {
            return { ...state, loading: true }
        },
        endLoadingUserSession: (state, action) => {
            return { ...state, loading: false, message: action.payload }
        },
        setUserData: (state, action) => {
            return { ...state, data: action.payload }
        }
    }
})

export const { startLoadingUserSession, endLoadingUserSession, setUserData } = userSlice.actions

export default userSlice.reducer