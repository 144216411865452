import { Star } from "@mui/icons-material";

export function BulletStar() {
  return (
    <Star
      fontSize="small"
      sx={{ color: "#f38a25", marginRight: "10px", marginLeft: "10px" }}
    />
  );
}
