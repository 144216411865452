import axios from "axios"
import { useState, ChangeEventHandler, ChangeEvent, useRef, MouseEvent } from "react"
import ConfirmModal from "../modals/confirm-modal/confirm-modal"
import {ChangeCircle} from '@mui/icons-material'
import Api from "../../api"
import { useDispatch } from "react-redux"
import Actions from "../../store/actions"

interface Props {
    onChange: ChangeEventHandler,
    name: string,
    pageFileName: string,
    oldImageName: string
}

export default function ImageChanger({ onChange, name, pageFileName, oldImageName }: Props) {

    const dispatch = useDispatch()
    const [imageFile, setImageFile] = useState<any>(null)
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);

    const handleClick = () => {
        if (hiddenFileInput !== null && hiddenFileInput.current !== null)
            hiddenFileInput.current.click();
    };

    const handleImageUpload = async () => {
        dispatch(Actions.startLoadingContentSession())
        await Api.changeImage(oldImageName === "" ? "noimage": oldImageName, pageFileName, imageFile)
            .then((response) => {
                setImageFile(null)
                console.log(response.data)
                onChange({ target: { name, value: (response.data).toString() } } as ChangeEvent<HTMLInputElement>)
                dispatch(Actions.endLoadingContentSession(""))
            })
            .catch((error) => {
                console.log(error);
                dispatch(Actions.endLoadingContentSession("ERROR"))
            })
    }

    const handleNo = () => {
        setImageFile(null)
    }


    return (
        <>
            <ConfirmModal open={imageFile !== null} handleConfirm={handleImageUpload} handelNo={handleNo}/>
            <input
                ref={hiddenFileInput}
                style={{ overflow: "hidden", width: "0px", height: "0px" }}
                type='file'
                onChange={(e) => { e.target.files !== null && e.target.files.length > 0 && setImageFile(e.target.files[0]) }}
            />
            <div
                onClick={handleClick}
                style={{color: "white", display: "flex", flexDirection: "column", alignItems: "center", fontWeight: "bold", padding: "15px", backgroundColor: "rgba(0,0,0,.5)", cursor: "pointer", border: "2px solid white", justifyContent: "center"}}
            >
                <ChangeCircle fontSize="large"/>
                Edit image
            </div>
        </>
    )
}