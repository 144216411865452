import { createSlice } from '@reduxjs/toolkit'
import { Amenity } from '../../models/Amenity'

export const amenitySlice = createSlice({
    name: 'amenity',
    initialState: {
        amenities: [] as Amenity[],
        loading: false,
        message: '',
        error: false
    },
    reducers: {
        startLoadingAmenitySession: (state) => {
            return { ...state, loading: true }
        },
        endLoadingAmenitySession: (state, action) => {
            return { ...state, loading: false, message: action.payload }
        },
        setAmenities: (state, action) => {
            return { ...state, amenities: action.payload }
        }
    }
})

export const { startLoadingAmenitySession, endLoadingAmenitySession, setAmenities } = amenitySlice.actions

export default amenitySlice.reducer