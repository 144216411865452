import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import PrimaryButton from '../../bottons/primary-button';
import logoImage from '../../../assets/images/logo/vectorpaint-black.svg';
import SecondaryButton from '../../bottons/secondary-button';

interface Props {
    open: boolean;
    handleConfirm: Function;
    handelNo: Function;
}

function ConfirmModal(props: Props) {

    const { open, handleConfirm, handelNo } = props

    return (
        <Modal
            open={open}
        >
            <Grid sx={{ position: "absolute", top: "50%", left: "50%", transform: 'translate(-50%, -50%)', backgroundColor: "white", padding: "20px", width: "95%", maxWidth: "400px" }}>
                <Grid sx={{ marginTop: "10px" }}>
                    <Typography variant='h6'><b>Are you sure?</b></Typography>
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                        <SecondaryButton sx={{ width: "40%" }} onClick={() => handelNo()}>No</SecondaryButton>
                        <PrimaryButton sx={{ width: "40%" }} onClick={() => handleConfirm()}>Yes</PrimaryButton>
                    </div>
                </Grid>
            </Grid>
        </Modal>
    )
}
export default ConfirmModal;