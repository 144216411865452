import { Box, Grid, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { Config } from '../../api/config';
import { useRef, useState } from 'react';
import Actions from '../../store/actions';
import Api from '../../api';
import ConfirmModal from '../../components/modals/confirm-modal/confirm-modal';
import PrimaryButton from '../../components/bottons/primary-button';
import LoadingModal from '../../components/modals/loading-modal/loading-modal';
import { Delete } from '@mui/icons-material';

function Gallery() {

    const dispatch = useDispatch()
    const gallerySession = useSelector((state: any) => state.gallerySession)
    const userSession = useSelector((state: any) => state.userSession)

    const [edit, setEdit] = useState(false)
    const [save, setSave] = useState(false)

    const [imageToDelete, setImageToDelete] = useState("")

    const [imageFile, setImageFile] = useState<any>(null)
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);

    const handleClick = () => {
        if (hiddenFileInput !== null && hiddenFileInput.current !== null)
            hiddenFileInput.current.click();
    };

    const handleEnableEditing = () => {
        setEdit(true)
    }

    const handleSaveChanges = () => {
        setEdit(false)
        setSave(false)
    }

    const GetGalleryImages = async () => {
        dispatch(Actions.startLoadingGallerySession())
        await Api.getGalleryImages()
            .then(response => {
                //console.log(response.data)
                dispatch(Actions.setImages(response.data))
                dispatch(Actions.endLoadingGallerySession(""))
            })
            .catch(err => {
                console.log(err)
                dispatch(Actions.endLoadingGallerySession("ERROR"))
            })
    }

    const handleImageUpload = async () => {
        dispatch(Actions.startLoadingGallerySession())
        await Api.addGalleryImage(imageFile)
            .then(response => {
                setImageFile(null)
                GetGalleryImages()
                dispatch(Actions.endLoadingGallerySession(""))
            })
            .catch(error => {
                console.log(error)
                dispatch(Actions.endLoadingGallerySession("ERROR"))
            })
    }

    const handleDeleteImage = async () => {
        dispatch(Actions.startLoadingGallerySession())
        await Api.deleteGalleryImage(imageToDelete)
            .then(response => {
                GetGalleryImages()
                setImageToDelete("")
                dispatch(Actions.endLoadingGallerySession(""))
            })
            .catch(error => {
                console.log(error)
                dispatch(Actions.endLoadingGallerySession("ERROR"))
            })
    }

    const handleNo = () => {
        setImageFile(null)
        setImageToDelete("")
    }

    return (
        <>
            <LoadingModal open={gallerySession.loading} />
            <ConfirmModal open={imageFile !== null} handleConfirm={handleImageUpload} handelNo={handleNo} />
            <ConfirmModal open={imageToDelete !== ""} handleConfirm={handleDeleteImage} handelNo={handleNo} />
            {
                userSession.data !== "" &&
                <Grid sx={{ position: "fixed", top: "10px", right: "10px", zIndex: "9999", visibility: save ? "hidden" : "visible" }}>
                    {
                        !edit ?
                            <PrimaryButton onClick={handleEnableEditing}>Enable editing</PrimaryButton>
                            :
                            <PrimaryButton onClick={() => { setSave(true) }}>Save changes</PrimaryButton>
                    }
                </Grid>
            }
            {
                !edit ?
                    <Grid container sx={{ height: { xs: "calc(100vh - 65px)", md: "calc(100vh - 110px)" }, backgroundColor: "rgba(130,139,178,0.1)", alignItems: "center", justifyContent: "center", flexWrap: "wrap-reverse", marginTop: { xs: "65px", md: "110px" } }}>
                        <Grid item xs={10} sx={{ position: "relative", height: "100%" }}>
                            <Carousel navButtonsAlwaysVisible indicators sx={{ padding: "0px", margin: "0px", height: "100%" }}>
                                {
                                    gallerySession.images !== null &&
                                    gallerySession.images.map((image: string) => (
                                        <Box key={image} sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: { xs: "calc(100vh - 105px)", md: "calc(100vh - 150px)" } }}>
                                            <img src={`${Config.galleryImageBaseURL}${image}`} style={{ maxHeight: "100%", maxWidth: "100%", }} />
                                        </Box>
                                    ))
                                }
                            </Carousel>
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Grid container sx={{ alignItems: "center", justifyContent: "center", marginTop: { xs: "65px", md: "110px" } }}>

                            {
                                gallerySession.images !== null &&
                                gallerySession.images.map((image: string) => (
                                    <Grid xs={11} md={3} sx={{ backgroundImage: `url(${Config.galleryImageBaseURL}${image})`, position: "relative", aspectRatio: "1", backgroundSize: "cover", backgroundPosition: "50% 50%", margin: "20px" }}>
                                        <Delete onClick={() => { setImageToDelete(image) }} sx={{ position: "absolute", bottom: "10px", right: "10px", color: "white", border: "1px solid white", padding: "10px", backgroundColor: "rgba(0,0,0,.6)", cursor: "pointer" }} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <Grid container sx={{ alignItems: "center", justifyContent: "center", margin: "50px 0px" }}>
                            <input
                                ref={hiddenFileInput}
                                style={{ overflow: "hidden", width: "0px", height: "0px" }}
                                type='file'
                                onChange={(e) => { e.target.files !== null && e.target.files.length > 0 && setImageFile(e.target.files[0]) }}
                            />

                            <PrimaryButton onClick={handleClick}>Add Image</PrimaryButton>
                        </Grid>
                    </>
            }
        </>
    )
}

export default Gallery;