import { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CircularProgress, Grid, TextField } from '@mui/material';
import PrimaryButton from '../../bottons/primary-button';

interface Props {
    open: boolean;
}


function LoadingModal(props: Props) {

    const { open } = props
    
    return (
        <Modal
            open={open}
        >
            <Grid item xs={11} md={4} lg={3} sx={{ display: "flex", alignItems: "center", position: "absolute", top: "50%", left: "50%", transform: 'translate(-50%, -50%)', backgroundColor: "white", padding: "20px", maxWidth: "400px" }}>
                <CircularProgress sx={{color: "rgb(0,157,255)"}} />
                <Typography sx={{ fontSize: "14px", color: "rgb(0,157,255)", marginLeft: "10px" }}>
                    Loading. Please wait...
                </Typography>
            </Grid>
        </Modal>
    )
}

export default LoadingModal;