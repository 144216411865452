import axios from 'axios'
import { AmenityRouts } from "../routes";
import { Config } from "../config";
import { AmenityTranslation } from '../../models/AmenityTranslation';

export const addAmenity = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${AmenityRouts.CREATE_AMENITY}`, {data})
    } catch (error) {
        throw new Error('Error...')
    }
}

export const getAmenities = async () => {
    try {
        return await axios.post(`${Config.baseURL}${AmenityRouts.GET_AMENITIES}`)
    } catch (error) {
        throw new Error('Error...')
    }
}

export const addAmenityTranslation = async (data: AmenityTranslation) => {
    try {
        return await axios.post(`${Config.baseURL}${AmenityRouts.CREATE_AMENITY_TRANSLATION}`,data)
    } catch (error) {
        throw error
    }
}

export const addAmenityByHouse = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${AmenityRouts.CREATE_AMENITY_BY_HOUSE}`,data)
    } catch (error) {
        throw error
    }
}

export const deleteAmenityByHouse = async (id: number) => {
    try {
        return await axios.post(`${Config.baseURL}${AmenityRouts.DELETE_AMENITY_BY_HOUSE}${id}`)
    } catch (error) {
        throw error
    }
}