export const HouseRouts = Object.freeze({
    ADD_HOUSE: 'houses/create',
    GET_ALL_HOUSES: 'houses/get',
    GET_HOUSE_BY_ID: '',
    UPDATE_HOUSE: 'houses/update/',
    DELETE_HOUSE: '',
})

export const AvailabilityRouts = Object.freeze({
    UPDATE_AVAILABILITY: '',
    DELETE_AVAILABILITY: '',
})

export const ReservationRouts = Object.freeze({
    CREATE_RESERVATION: '',
    GET_ALL_RESERVATIONS: '',
    GET_RESERVATION_BY_ID: '',
    UPDATE_RESERVATION: '',
    DELETE_RESERVATION: '',
})

export const UnavailableAppointmentRouts = Object.freeze({
    GET_UNAVAILABLE_APPOINTMENTS: 'unavailable-appointments/get-by-months',
    GET_UNAVAILABLE_APPOINTMENTS_FOR_AL_HOUSES: 'unavailable-appointments/get-by-months-for-all-houses',
    UPDATE_UNAVAILABLE_APPOINTMENT: '',
    DELETE_UNAVAILABLE_APPOINTMENT: '',
})

export const EmailRouts = Object.freeze({
    VALIDATE_EMAIL: 'emails',
    SEND_CONTACT_FORM: 'emails/send-contact-form',
})

export const ContentRouts = Object.freeze({
    SET_HOME_PAGE: 'contents/set-home-page',
    SET_ACCOMMODATION_PAGE: 'contents/set-accommodation-page',
    SET_EXPERIENCES_PAGE: 'contents/set-experiences-page',
    SET_FACILITIES_PAGE: 'contents/set-facilities-page',
    SET_CONTACT_PAGE: 'contents/set-contact-page',
    GET_CONTENT: 'contents/get-content',
    CHANGE_IMAGE: 'images/change-image/'
})

export const TranslationRouts = Object.freeze({
    CREATE_TRANSLATION: 'house-translations/create',
    UPDATE_TRANSLATION: 'house-translations/update',
    DELETE_TRANSLATION: '',
})

export const ImageRouts = Object.freeze({
    CREATE_IMAGE: 'images/create',
    DELETE_IMAGE: 'images/delete/',
})

export const AmenityRouts = Object.freeze({
    GET_AMENITIES: 'amenities/get',
    CREATE_AMENITY: 'amenities/create',
    DELETE_AMENITY: 'amenities/delete/',
    CREATE_AMENITY_TRANSLATION: 'amenity-translations/create',
    CREATE_AMENITY_BY_HOUSE: 'amenities-by-houses/create',
    DELETE_AMENITY_BY_HOUSE: 'amenities-by-houses/delete/',
})

export const FeatureRouts = Object.freeze({
    CREATE_FEATURE: 'features/create',
    UPDATE_FEATURE: 'features/update',
    DELETE_FEATURE: 'features/delete/',
})

export const GalleryRouts = Object.freeze({
    GET_IMAGES: 'gallery/get-images',
    CREATE_IMAGE: 'gallery/add-image',
    DELETE_IMAGE: 'gallery/delete-image/',
})

export const UserRouts = Object.freeze({
    LOG_IN: 'users/login',
    LOG_OUT: 'users/logout',
    GET_DATA: 'users/get-user'
})

export const BeachRouts = Object.freeze({
    CREATE: 'beaches/create',
    GET: 'beaches/get',
    UPDATE: 'beaches/update',
    DELETE: 'beaches/delete/',
    ADD_IMAGE: 'beaches/add-image/',
    DELETE_IMAGE: 'beaches/delete-image/'
})