import { Box, Grid, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import PrimaryButton from "../../components/bottons/primary-button";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { House } from "../../models/House";
import LoadingModal from "../../components/modals/loading-modal/loading-modal";
import { AmenityByHouse } from "../../models/AmenityByHouse";
import { Feature } from "../../models/Feature";
import Actions from "../../store/actions";
import Api from "../../api";
import { HouseTranslation } from "../../models/HouseTranslation";
import ConfirmModal from "../../components/modals/confirm-modal/confirm-modal";
import FeatureCard from "../../components/cards/feature-card/feature-card";
import FeatureCreator from "../../components/feature-creator/feature-creator";
import { Amenity } from "../../models/Amenity";
import AmenityCard from "../../components/cards/amenity-card/amenity-card";
import ImageCard from "../../components/cards/image-card/image-card";
import HouseImageUploader from "../../components/house-image-uploader/house-image-uploader";
import { Image } from "../../models/Image";
import { Config } from "../../api/config";
import AmenityCreator from "../../components/amenity-creator/amenity-creator";
import { Star } from "@mui/icons-material";
import { BulletStar } from "../../components/bullet-star/BulletStar";
import {
  RoomRaccoonVillaLemonUrl,
  RoomRaccoonVillaOliveUrl,
  RoomRaccoonVillaPineUrl,
} from "../../models/constants/Links";

function Details() {
  const { name } = useParams();
  const dispatch = useDispatch();

  const houseSession = useSelector((state: any) => state.houseSession);
  const amenitySession = useSelector((state: any) => state.amenitySession);
  const userSession = useSelector((state: any) => state.userSession);

  const [housesData, setHousesData] = useState<House[]>([]);
  const [data, setData] = useState<House | null>(null);
  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);

  const [longDescription, setLongDescription] = useState("");
  const [translationData, setTranslationData] =
    useState<HouseTranslation | null>(null);
  const [features, setFeatures] = useState<Feature[]>([]);

  const [editImages, setEditImages] = useState<boolean>(false);
  const [images, setImages] = useState<Image[]>([]);

  const [diffAmenities, setDiffAmenities] = useState<Amenity[]>([]);
  const [allAmenities, setAllAmenities] = useState<Amenity[]>([]);

  const handleEnableEditing = () => {
    setEdit(true);
  };

  const getAmenities = async () => {
    dispatch(Actions.startLoadingAmenitySession());
    await Api.getAmenities()
      .then((response) => {
        setAllAmenities(response.data);
        dispatch(Actions.endLoadingAmenitySession(""));
      })
      .catch((error) => {
        console.log(error);
        dispatch(Actions.endLoadingAmenitySession("ERROR"));
      });
  };

  const getDifference = (array1: Amenity[], array2: Amenity[]) => {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.id === object2.id;
      });
    });
  };

  const GetHouses = async () => {
    dispatch(Actions.startLoadingHouseSession());
    await Api.getHouses()
      .then((response) => {
        dispatch(Actions.setAllHouses(response.data));
        dispatch(Actions.endLoadingHouseSession(""));
      })
      .catch((err) => {
        console.log(err);
        dispatch(Actions.setAllHouses([]));
        dispatch(Actions.endLoadingHouseSession("ERROR"));
      });
  };
  const handleSaveChanges = () => {
    setEdit(false);
    setSave(false);
  };

  const updateHouseTranslation = async () => {
    if (
      translationData !== null &&
      longDescription !== undefined &&
      longDescription !== translationData.longDescription
    ) {
      dispatch(Actions.startLoadingHouseSession());
      await Api.updateHouseTranslation({
        id: translationData.id,
        name: translationData.name,
        shortDescription: translationData.shortDescription,
        longDescription: longDescription,
        languageId: 1,
        houseId: translationData.houseId,
        language: null,
        house: null,
      })
        .then((response) => {
          GetHouses();
          dispatch(Actions.endLoadingHouseSession(""));
        })
        .catch((err) => {
          console.log(err);
          dispatch(Actions.endLoadingHouseSession("ERROR"));
        });
    }
  };

  useEffect(() => {
    if (!edit) {
      updateHouseTranslation();
    }
  }, [edit]);

  useEffect(() => {
    setHousesData(
      houseSession.allHouses.filter(
        (x: House) =>
          x.houseTranslations !== null &&
          x.houseTranslations.length > 0 &&
          x.houseTranslations[0].name === name
      )
    );
  }, [houseSession.allHouses]);

  useEffect(() => {
    if (housesData.length > 0) setData(housesData[0]);
  }, [housesData]);

  useEffect(() => {
    if (
      data !== null &&
      data.houseTranslations !== null &&
      data.houseTranslations.length > 0 &&
      data.houseTranslations[0] !== null
    ) {
      setTranslationData(data.houseTranslations[0]);
      setLongDescription(data.houseTranslations[0].longDescription);
    }
    if (data !== null && data.features !== null) {
      setFeatures(data.features);
    }
    if (allAmenities.length === 0) {
      getAmenities();
    }
    if (data !== null && data.images !== null && images.length === 0) {
      setImages(data.images);
    }
  }, [data]);

  useEffect(() => {
    if (allAmenities.length > 0) {
      var helperArr = [] as Amenity[];
      data?.amenitiesByHouses?.forEach((amenityByHouse: AmenityByHouse) => {
        if (amenityByHouse.amenity !== null)
          helperArr.push(amenityByHouse.amenity);
      });
      setDiffAmenities(getDifference(allAmenities, helperArr));
    }
  }, [data, allAmenities]);

  const houseName = useMemo(() => {
    if (data !== null && data.houseTranslations && data.houseTranslations[0])
      return data.houseTranslations[0].name;
    return "";
  }, [data]);

  const bookNowUrl = useMemo(() => {
    switch (houseName) {
      case "Villa Lemon": {
        return RoomRaccoonVillaLemonUrl;
      }
      case "Villa Olive": {
        return RoomRaccoonVillaOliveUrl;
      }
      case "Villa Pine": {
        return RoomRaccoonVillaPineUrl;
      }
      default: {
        return "";
      }
    }
  }, [houseName]);

  return (
    <>
      <LoadingModal open={houseSession.loading || amenitySession.loading} />
      <ConfirmModal
        open={save}
        handelNo={() => {
          setSave(false);
        }}
        handleConfirm={handleSaveChanges}
      />
      {data !== null ? (
        <>
          {userSession.data !== "" && (
            <Grid
              sx={{
                position: "fixed",
                top: "10px",
                right: "10px",
                zIndex: "9999",
                visibility: save ? "hidden" : "visible",
              }}
            >
              {!edit ? (
                <PrimaryButton onClick={handleEnableEditing}>
                  Enable editing
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    setSave(true);
                  }}
                >
                  Save changes
                </PrimaryButton>
              )}
            </Grid>
          )}
          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap-reverse",
              marginTop: { xs: "65px", md: "110px" },
            }}
          >
            <Grid
              item
              xs={10}
              md={8}
              xl={4}
              lg={5}
              sx={{ position: "relative" }}
            >
              <Carousel
                navButtonsAlwaysVisible
                indicators
                sx={{ padding: "0px", margin: "0px" }}
              >
                {images !== null &&
                  images.map((image, index) => (
                    <Box
                      key={index}
                      sx={{
                        backgroundImage: `url('${Config.houseImageBaseURL}${image.bigImageName}')`,
                        backgroundPosition: "50% 50%",
                        backgroundSize: "cover",
                        aspectRatio: "1",
                      }}
                    ></Box>
                  ))}
              </Carousel>
              {edit && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "40px",
                    right: "10px",
                    zIndex: "999",
                  }}
                >
                  <PrimaryButton
                    onClick={() => {
                      setEditImages(!editImages);
                    }}
                  >
                    {editImages ? "Close edit images" : "Open edit images"}
                  </PrimaryButton>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={10}
              md={8}
              xl={3}
              lg={3}
              sx={{ margin: { xs: "20px auto", lg: "50px 0px 150px 50px" } }}
            >
              <Typography variant="h3">
                {data.houseTranslations &&
                  data.houseTranslations.length > 0 &&
                  data.houseTranslations[0].name}
              </Typography>
              <Grid
                xs={12}
                sx={{
                  padding: "30px",
                  marginTop: "50px",
                  backgroundColor: "rgba(130,139,178,0.1)",
                }}
              >
                {!edit
                  ? data.features !== null &&
                    data.features.map((feature: Feature) => {
                      return (
                        <div
                          key={feature.name}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          <BulletStar />
                          <Typography variant="body1">
                            {feature.name}
                          </Typography>
                        </div>
                      );
                    })
                  : features.map((feature: Feature) => {
                      return (
                        <FeatureCard
                          key={feature.name}
                          getData={GetHouses}
                          data={feature}
                          setFeatures={setFeatures}
                        />
                      );
                    })}
              </Grid>
              {!edit ? (
                <a href={bookNowUrl} target="_blank" rel="noopener noreferrer">
                  <PrimaryButton sx={{ width: "100%" }}>Book now</PrimaryButton>
                </a>
              ) : (
                <FeatureCreator houseId={data.id} callback={GetHouses} />
              )}
            </Grid>
          </Grid>
          {editImages && (
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid
                item
                xs={12}
                lg={9}
                xl={7}
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px dashed #009DFF",
                }}
              >
                {images.length > 0 &&
                  images.map((image: Image) => {
                    return (
                      <ImageCard
                        deleteCallback={GetHouses}
                        key={image.smallImageName}
                        image={image}
                        setImages={setImages}
                      />
                    );
                  })}
              </Grid>
              <Grid
                item
                xs={12}
                md={11}
                lg={9}
                xl={7}
                sx={{
                  position: "relative",
                  margin: "50px 0px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <HouseImageUploader
                  callback={GetHouses}
                  setImages={setImages}
                  houseId={data.id}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            container
            sx={{
              justifyContent: "center",
              backgroundColor: "rgba(130,139,178,0.1)",
            }}
          >
            <Grid item xs={12} md={11} lg={9} xl={7} sx={{}}>
              <Typography variant="h4" sx={{ margin: "50px 20px" }}>
                Information
              </Typography>

              {!edit ? (
                <>
                  <Typography variant="body1" sx={{ margin: "50px 20px" }}>
                    {data.houseTranslations &&
                      data.houseTranslations.length > 0 &&
                      data.houseTranslations[0].longDescription}
                  </Typography>
                </>
              ) : (
                <>
                  <textarea
                    style={{ height: "300px" }}
                    className="admin-textarea"
                    value={longDescription}
                    onChange={(e) => {
                      setLongDescription(e.target.value);
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={11} lg={9} xl={7}>
              <Typography variant="h4" sx={{ margin: "50px 20px" }}>
                Amenities
              </Typography>
              {edit && (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      color: "orange",
                      margin: "20px 10px 0px 10px",
                    }}
                  >
                    Click on amenity to remove.
                  </Typography>
                </>
              )}
              <Grid
                xs={12}
                sx={{ margin: "50px 20px", flexWrap: "wrap", display: "flex" }}
              >
                {data.amenitiesByHouses &&
                  data.amenitiesByHouses.length > 0 &&
                  data.amenitiesByHouses.map(
                    (amenityByHouse: AmenityByHouse) => {
                      return (
                        amenityByHouse.amenity !== null &&
                        amenityByHouse.amenity.amenityTranslations !== null &&
                        amenityByHouse.amenity.amenityTranslations.length >
                          0 && (
                          <>
                            {!edit ? (
                              <Grid
                                key={
                                  amenityByHouse.amenity.amenityTranslations[0]
                                    .name
                                }
                                xs={11}
                                sm={6}
                                md={4}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <BulletStar />
                                  <Typography variant="body1">
                                    {
                                      amenityByHouse.amenity
                                        .amenityTranslations[0].name
                                    }
                                  </Typography>
                                </div>
                              </Grid>
                            ) : (
                              <>
                                <AmenityCard
                                  key={"am" + amenityByHouse.amenity.id}
                                  data={amenityByHouse.amenity}
                                  amenityByHouse={amenityByHouse}
                                  houseId={data.id}
                                  selected={true}
                                  callback={GetHouses}
                                />
                              </>
                            )}
                          </>
                        )
                      );
                    }
                  )}
              </Grid>
              {edit && (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      color: "orange",
                      margin: "20px 10px 0px 10px",
                    }}
                  >
                    Click on amenity to add or create new amenity.
                  </Typography>
                  <Grid
                    xs={12}
                    sx={{
                      margin: "50px 20px",
                      flexWrap: "wrap",
                      display: "flex",
                    }}
                  >
                    {diffAmenities.map((amenity: Amenity) => {
                      return (
                        <AmenityCard
                          key={"am" + amenity.id}
                          data={amenity}
                          houseId={data.id}
                          selected={false}
                          callback={GetHouses}
                        />
                      );
                    })}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={11}
                    lg={9}
                    xl={7}
                    sx={{
                      position: "relative",
                      margin: "40px 0px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AmenityCreator callback={getAmenities} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            sx={{ height: "100vh", backgroundColor: "white" }}
          ></Grid>
        </>
      )}
    </>
  );
}

export default Details;
