import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid} from '@mui/material';
import PrimaryButton from '../../bottons/primary-button';
import logoImage from '../../../assets/images/logo/vectorpaint-black.svg';

interface Props {
    message: string;
    open: boolean;
    handleConfirm: Function;
}

function NotificationModal(props: Props) {

    const { open, handleConfirm, message } = props

    return (
        <Modal
            open={open}
        >
            <Grid sx={{ position: "absolute", top: "50%", left: "50%", transform: 'translate(-50%, -50%)', backgroundColor: "white", padding: "20px", width: "95%", maxWidth: "400px" }}>
                <Grid sx={{ display: "flex", alignItems: "flex-end" }}>
                    <img src={logoImage} alt='logo' style={{height: "25px", marginRight: "10px"}}/>
                    Nikiti Dream
                </Grid>
                <Grid sx={{ marginTop: "10px" }}>
                    <br />
                    <Typography variant='h6'><b>{message}</b></Typography>
                    <br />
                    <PrimaryButton sx={{ width: "100%" }} onClick={()=>handleConfirm()}>Ok</PrimaryButton>
                </Grid>
            </Grid>
        </Modal>
    )
}
export default NotificationModal;