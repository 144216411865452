import axios from 'axios'
import { TranslationRouts } from "../routes";
import { Config } from "../config";
import { HouseTranslation } from '../../models/HouseTranslation';

export const addHouseTranslation = async (data: HouseTranslation) => {
    try {
        return await axios.post(`${Config.baseURL}${TranslationRouts.CREATE_TRANSLATION}`,data)
    } catch (error) {
        throw error
    }
}

export const updateHouseTranslation = async (data: HouseTranslation) => {
    try {
        return await axios.post(`${Config.baseURL}${TranslationRouts.UPDATE_TRANSLATION}`,data)
    } catch (error) {
        throw error
    }
}