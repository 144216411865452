

import { Grid, Typography } from '@mui/material';

import img1 from '../../assets/images/facilities1.jpg';
import img2 from '../../assets/images/facilities2.jpg';
import img3 from '../../assets/images/facilities3.jpg';
import img4 from '../../assets/images/facilities4.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useEffect, useState } from 'react';
import Actions from '../../store/actions';
import Api from '../../api';
import { createFacilitiesObjectFromArray } from '../../assets/helpers/content-data';
import LoadingModal from '../../components/modals/loading-modal/loading-modal';
import ConfirmModal from '../../components/modals/confirm-modal/confirm-modal';
import PrimaryButton from '../../components/bottons/primary-button';
import ImageChanger from '../../components/image-changer/image-changer';
import { Config } from '../../api/config';


interface ContentData {
    [key: string]: string;
}

function Facilities() {

    const dispatch = useDispatch()
    const contentSession = useSelector((state: any) => state.contentSession)
    const userSession = useSelector((state: any) => state.userSession)

    const [edit, setEdit] = useState(false)
    const [save, setSave] = useState(false)

    const fileName = "Facilities"

    const [contentData, setContentData] = useState<ContentData | null>(null)

    const handleEnableEditing = () => {
        setEdit(true)
    }

    const handleSaveChanges = () => {
        setEdit(false)
        setSave(false)
        SetAccommodationPage()
    }

    const SetAccommodationPage = async () => {
        dispatch(Actions.startLoadingContentSession())
        if (contentData !== null)
            await Api.setFacilitiesPage(Object.values(contentData))
                .then(response => {
                    dispatch(Actions.setAccommodationContent(createFacilitiesObjectFromArray(response.data)))
                    dispatch(Actions.endLoadingContentSession(""))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(Actions.endLoadingContentSession("ERROR"))
                })
    }

    const inputChangeHandler = () => {
        return ({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (contentData !== null)
                setContentData((prev: ContentData | null) => ({
                    ...prev,
                    [name]: value,
                }));
        };
    };

    useEffect(() => {
        if (contentData === null && contentSession.facilities !== null)
            setContentData(contentSession.facilities)

    }, [contentSession])

    return (
        <>
            <LoadingModal open={contentSession.loading} />
            <ConfirmModal open={save} handelNo={() => { setSave(false) }} handleConfirm={handleSaveChanges} />
            {contentData !== null ?
                <>
                    {
                        userSession.data !== "" &&
                        <Grid sx={{ position: "fixed", top: "10px", right: "10px", zIndex: "9999", visibility: save ? "hidden" : "visible" }}>
                            {
                                !edit ?
                                    <PrimaryButton onClick={handleEnableEditing}>Enable editing</PrimaryButton>
                                    :
                                    <PrimaryButton onClick={() => { setSave(true) }}>Save changes</PrimaryButton>
                            }
                        </Grid>
                    }
                    <Grid className="facilities-header" container sx={{ position: "relative", backgroundImage: `url(${Config.imageBaseURL}${contentData.headerImage})` }}>
                        <Grid container sx={{ position: "relative", textAlign: "center", display: "flex", justifyContent: "center", margin: "50px 0px", alignItems: "center" }}>
                            <Typography variant='h3' sx={{ fontWeight: "bold", color: "white" }}>
                                Facilities
                            </Typography>
                        </Grid>
                        {
                            edit &&
                            <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
                                <ImageChanger pageFileName={fileName} oldImageName={contentData.headerImage} name='headerImage' onChange={inputChangeHandler()} />
                            </div>
                        }
                    </Grid>
                    {
                        ((contentData.title1 !== "" && contentData.text1 !== "") || edit) &&
                        <Grid container sx={{ alignContent: "center", justifyContent: "center" }}>
                            <Grid item xs={10} md={8} xl={3} lg={3} sx={{ margin: { xs: "20px auto", lg: "150px 50px 150px 0px" } }}>
                                {
                                    !edit ?
                                        <>
                                            <Typography variant='h3'>
                                                {contentData.title1}
                                            </Typography>
                                            <Typography variant='body1' sx={{ marginTop: "50px" }}>
                                                {contentData.text1}
                                            </Typography>
                                        </>
                                        :
                                        <>
                                            <input className='admin-input' type='text' name="title1" value={contentData.title1} onChange={inputChangeHandler()}></input>
                                            <br />
                                            <textarea className='admin-textarea' name="text1" value={contentData.text1} onChange={inputChangeHandler()} />
                                        </>
                                }
                            </Grid>
                            <Grid item xs={10} md={8} xl={4} lg={5} sx={{ margin: { xs: "20px auto", lg: "100px 0px 120px 0px" }, alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                                <div style={{ backgroundImage: `url(${Config.imageBaseURL}${contentData.image1})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginBottom: "50px", width: "48%", aspectRatio: "3/4" }}>
                                    {
                                        edit &&
                                        <ImageChanger pageFileName={fileName} oldImageName={contentData.image1} name='image1' onChange={inputChangeHandler()} />
                                    }
                                </div>
                                <div style={{ backgroundImage: `url(${Config.imageBaseURL}${contentData.image2})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginTop: "50px", width: "48%", aspectRatio: "3/4" }}>
                                    {
                                        edit &&
                                        <ImageChanger pageFileName={fileName} oldImageName={contentData.image2} name='image2' onChange={inputChangeHandler()} />
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    }
                    {
                        ((contentData.title2 !== "" && contentData.text2 !== "") || edit) &&

                        <Grid container sx={{ alignContent: "center", justifyContent: "center", backgroundColor: "rgba(130,139,178,0.1)", flexWrap: "wrap-reverse" }}>
                            <Grid item xs={10} md={8} xl={4} lg={5} sx={{ margin: { xs: "20px auto", lg: "100px 0px 120px 0px" }, alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                                <div style={{ backgroundImage: `url(${Config.imageBaseURL}${contentData.image3})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginBottom: "50px", width: "48%", aspectRatio: "3/4" }}>
                                    {
                                        edit &&
                                        <ImageChanger pageFileName={fileName} oldImageName={contentData.image3} name='image3' onChange={inputChangeHandler()} />
                                    }
                                </div>
                                <div style={{ backgroundImage: `url(${Config.imageBaseURL}${contentData.image4})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginTop: "50px", width: "48%", aspectRatio: "3/4" }}>
                                    {
                                        edit &&
                                        <ImageChanger pageFileName={fileName} oldImageName={contentData.image4} name='image4' onChange={inputChangeHandler()} />
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={10} md={8} xl={3} lg={3} sx={{ margin: { xs: "20px auto", lg: "150px 0px 150px 50px" } }}>
                                {
                                    !edit ?
                                        <>
                                            <Typography variant='h3'>
                                                {contentData.title2}
                                            </Typography>
                                            <Typography variant='body1' sx={{ marginTop: "50px" }}>
                                                {contentData.text2}
                                            </Typography>
                                        </>
                                        :
                                        <>
                                            <input className='admin-input' type='text' name="title2" value={contentData.title2} onChange={inputChangeHandler()}></input>
                                            <br />
                                            <textarea className='admin-textarea' name="text2" value={contentData.text2} onChange={inputChangeHandler()} />
                                        </>
                                }
                            </Grid>
                        </Grid>
                    }
                </>
                :
                <>
                    <Grid container sx={{ height: "100vh", backgroundColor: "white" }}></Grid>
                </>
            }
        </>
    )
}

export default Facilities;