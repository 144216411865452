import axios from 'axios'
import { FeatureRouts } from "../routes";
import { Config } from "../config";
import { Feature } from '../../models/Feature';

export const addFeature = async (data: Feature) => {
    try {
        return await axios.post(`${Config.baseURL}${FeatureRouts.CREATE_FEATURE}`, data)
    } catch (error) {
        throw error
    }
}

export const updateFeature = async (data: Feature) => {
    try {
        return await axios.post(`${Config.baseURL}${FeatureRouts.UPDATE_FEATURE}`, data)
    } catch (error) {
        throw error
    }
}

export const deleteFeature = async (id: number) => {
    try {
        return await axios.post(`${Config.baseURL}${FeatureRouts.DELETE_FEATURE}${id}`)
    } catch (error) {
        throw error
    }
}