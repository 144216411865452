import axios from 'axios'
import { ReservationRouts } from "../routes";
import { Config } from "../config";

export const createReservation = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${ReservationRouts.CREATE_RESERVATION}`, {data})
    } catch (error) {
        throw error
    }
}

export const getReservations = async () => {
    try {
        return await axios.post(`${Config.baseURL}${ReservationRouts.GET_ALL_RESERVATIONS}`, {})
    } catch (error) {
        throw error
    }
}

export const getReservationById = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${ReservationRouts.GET_RESERVATION_BY_ID}`, {data})
    } catch (error) {
        throw error
    }
}

export const updateReservation = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${ReservationRouts.UPDATE_RESERVATION}`, {data})
    } catch (error) {
        throw error
    }
}

export const deleteReservation = async (data: any) => {
    try {
        return await axios.delete(`${Config.baseURL}${ReservationRouts.DELETE_RESERVATION}`, {data})
    } catch (error) {
        throw error
    }
}