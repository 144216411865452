import { Typography } from '@mui/material'
import mapImage from '../../assets/images/map.jpg'
import {useState} from 'react'


function Maps() {

    const [hover, setHover] = useState(false)

    const handleMouseIn = () => {
        setHover(true)
    }

    const handleMouseOut = () => {
        setHover(false)
    }
    return (
        <div onMouseEnter={handleMouseIn} onMouseLeave={handleMouseOut} style={{backgroundImage: `url('${mapImage}')`, backgroundSize: "cover", backgroundPosition: "50% 50%", width: "100%", height: "300px"}}>
            {
                hover &&
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,.5)", color: "white"}}>
                    <Typography variant='h5'>
                        Open on Google maps
                    </Typography>
                </div>
            }
        </div>
    )
};

export default Maps;