import { Delete, Edit } from "@mui/icons-material";
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react"
import PrimaryButton from "../bottons/primary-button";
import ConfirmModal from "../modals/confirm-modal/confirm-modal";

interface Props {
    name: string,
    values: string[],
    onChange: ChangeEventHandler
}

export default function ExperiencesItems({ name, values, onChange }: Props) {

    const [stringArray, setStringArray] = useState(values)
    const [newValue, setNewValue] = useState("")
    const [newName, setName] = useState("")
    const [editIndex, setEditIndex] = useState(-1)
    const [save, setSave] = useState(false)

    const handleInputChange = (value: string, index: number) => {
        const newArray = [...stringArray];
        newArray[index] = value;
        onChange({ target: { name, value: newArray.join(';') } } as ChangeEvent<HTMLInputElement>)
        setStringArray(newArray);
    };

    const handleDelete = (value: string) => {
        const newArray = stringArray.filter(x => x !== value)
        onChange({ target: { name, value: newArray.join(';') } } as ChangeEvent<HTMLInputElement>)
        setStringArray(newArray);
    }

    const handleAdd = () => {
        const newArray = [...stringArray, newValue]
        setStringArray(newArray);
        onChange({ target: { name, value: newArray.join(';') } } as ChangeEvent<HTMLInputElement>)
        setNewValue("")
    }

    const handleClickEdit = (value: string, index: number) => {
        setName(value)
        setEditIndex(index)
    }

    const handleConfirm = () => {
        setSave(false)
        handleInputChange(newName, editIndex)
        setEditIndex(-1)
    }

    const handleNo = () => {
        setSave(false)
        setEditIndex(-1)
    }

    return (
        <>
            <ConfirmModal open={save} handelNo={handleNo} handleConfirm={handleConfirm} />
            {
                stringArray.map((value: string, index: number) => {
                    return (
                        <div key={value} style={{ display: "flex", alignItems: "center", border: "1px solid #009dff", padding: "5px", boxSizing: "border-box", margin: "5px 0px", width: "95%" }}>
                            {
                                editIndex === index ?
                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        <input type='text' style={{ width: "100%", boxSizing: "border-box" }} className='admin-input' value={newName} onChange={(e) => { setName(e.target.value) }} />
                                        <br />
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <PrimaryButton onClick={() => { setSave(true) }}>Ok</PrimaryButton>
                                            <PrimaryButton onClick={() => { setEditIndex(-1); setNewValue("") }}>Cancel</PrimaryButton>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                        <div>
                                            {value}
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Edit onClick={() => { handleClickEdit(value, index) }} sx={{ color: "black", padding: "5px", border: "1px solid black", borderRadius: "5px", marginRight: "5px" }} />
                                            <Delete onClick={() => { handleDelete(value) }} sx={{ color: "black", padding: "5px", border: "1px solid black", borderRadius: "5px" }} />
                                        </div>
                                    </div>
                            }


                        </div>
                    )
                })
            }
            <div style={{ margin: "10px 0px", width: "95%", borderTop: "1px solid grey", display: "flex", alignItems: "center" }}>
                <input placeholder="Add new item" style={{ height: "25px", margin: "5px 0px" }} className="admin-input" value={newValue} onChange={(e) => { setNewValue(e.target.value) }} />
                <PrimaryButton sx={{ height: "30px", marginLeft: "10px" }} onClick={handleAdd}>Add</PrimaryButton>
            </div>
        </>
    )
}