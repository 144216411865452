import { getHouses, getHouseById, addHouse, updateHouse, deleteHouse } from "./endpoints/house"
import { updateAvailability, deleteAvailability } from "./endpoints/availability"
import { createReservation, getReservations, getReservationById, updateReservation, deleteReservation } from "./endpoints/reservation"
import { getUnavailableAppointments, getUnavailableAppointmentsForAllHouses } from "./endpoints/unavailableAppointment"
import { validateEmail, sendContactForm } from "./endpoints/email"
import { getContent, setHomePage, setAccommodationPage, setExperiencesPage, setFacilitiesPage, setContactPage, changeImage } from "./endpoints/content"
import { addHouseTranslation, updateHouseTranslation } from "./endpoints/translations"
import { addImage, deleteImage } from "./endpoints/image"
import { addAmenity, getAmenities, addAmenityTranslation, addAmenityByHouse, deleteAmenityByHouse } from "./endpoints/amenity"
import { addFeature, updateFeature, deleteFeature } from "./endpoints/feature"
import { getGalleryImages, addGalleryImage, deleteGalleryImage } from "./endpoints/gallery"
import { userLogin, userLogout, getUser } from "./endpoints/user"
import { getBeaches, addBeach, updateBeach, deleteBeach, addBeachImage, deleteBeachImage } from "./endpoints/beach"

const Api = {
    getHouses,
    getHouseById,
    addHouse,
    updateHouse,
    deleteHouse,
    updateAvailability,
    deleteAvailability,
    createReservation, 
    getReservations, 
    getReservationById, 
    updateReservation, 
    deleteReservation,
    getUnavailableAppointments,
    getUnavailableAppointmentsForAllHouses,
    validateEmail,
    sendContactForm,

    getContent,
    changeImage,
    setHomePage,
    setAccommodationPage,
    setExperiencesPage,
    setFacilitiesPage,
    setContactPage,
    
    addHouseTranslation,
    updateHouseTranslation,

    addImage,
    deleteImage,

    addAmenity,
    getAmenities,
    addAmenityTranslation,
    addAmenityByHouse,
    deleteAmenityByHouse,

    addFeature,
    updateFeature,
    deleteFeature,

    getGalleryImages,
    addGalleryImage,
    deleteGalleryImage,

    userLogin,
    userLogout,
    getUser,

    getBeaches, 
    addBeach, 
    updateBeach, 
    deleteBeach, 
    addBeachImage, 
    deleteBeachImage
}

export default Api;