import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { MainThemeColor } from "../../models/constants/Colors";

const PrimaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "white",
  backgroundColor: MainThemeColor,
  borderRadius: "0px",
  padding: "10px 30px",
  boxShadow: "none",
  border: `1px solid ${MainThemeColor}`,

  "&:hover": {
    backgroundColor: "white",
    color: MainThemeColor,
    border: `1px solid ${MainThemeColor}`,
  },

  "&:disabled": {
    opacity: "0.5",
    color: "white",
  },
}));

export default PrimaryButton;
