import axios from 'axios'
import { EmailRouts } from "../routes";
import { Config } from "../config";

export const validateEmail = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${EmailRouts.VALIDATE_EMAIL}`, data)
    } catch (error) {
        throw error
    }
}

export const sendContactForm = async (data: any) => {
    try {
        return await axios.post(`${Config.baseURL}${EmailRouts.SEND_CONTACT_FORM}`, data)
    } catch (error) {
        throw error
    }
}