import { Box, Grid, Typography } from "@mui/material";

import panoramicViewImg0 from "./images/PanoramicView0.jpg";
import panoramicViewImg1 from "./images/PanoramicView1.jpg";
import panoramicViewImg2 from "./images/PanoramicView2.jpg";
import panoramicViewImg3 from "./images/PanoramicView3.jpg";
import panoramicViewImg4 from "./images/PanoramicView4.jpg";

function PanoramicView() {
  return (
    <>
      <Box
        data-testid="header"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
          height: "500px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: panoramicViewImg0,
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold", color: "black" }}>
          Panoramic view
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          alignContent: "center",
          justifyContent: "center",
          flexWrap: "wrap-reverse",
        }}
      >
        <Grid
          item
          xs={10}
          md={8}
          xl={4}
          lg={5}
          sx={{
            margin: { xs: "20px auto", lg: "100px 0px 120px 0px" },
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              backgroundImage: `url("./assets/images/panoramic/PanoramicView0.jpg")`,
              backgroundColor: "red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
              marginRight: "20%",
              width: "80%",
              aspectRatio: "4/2",
            }}
          />
          <img
            src={panoramicViewImg0}
            alt=""
            style={{ height: "500px", width: "500px" }}
          />
          <div
            style={{
              backgroundImage: panoramicViewImg2,
              backgroundColor: "pink",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
              marginTop: "25px",
              marginLeft: "20%",
              width: "80%",
              aspectRatio: "4/2",
            }}
          />
          <div
            style={{
              backgroundColor: "green",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
              marginTop: "25px",
              marginRight: "20%",
              width: "80%",
              aspectRatio: "4/2",
            }}
          />
          <div
            style={{
              backgroundColor: "yellow",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
              marginTop: "25px",
              marginLeft: "20%",
              width: "80%",
              aspectRatio: "4/2",
            }}
          />
        </Grid>
        <Grid
          item
          xs={10}
          md={8}
          xl={3}
          lg={3}
          sx={{ margin: { xs: "20px auto", lg: "150px 50px" } }}
        >
          <Typography variant="h3">Sea and Mountain View </Typography>
          <Typography variant="body1" sx={{ marginTop: "50px" }}>
            Sea and Mountain View From the top of the hill, the world seemed to
            expand and contract all at once. The huge expanse of the sea
            stretching out in front of you, a canvas of shifting blues connects
            with the sky via the first leg Kassandra of the Chalkidiki
            peninsula. Directly across, you’ll find the village of Kallithea.
            Below, a symphony of olive trees painted the landscape in shades of
            green. Their strong trunks stand in proud rows, each tree a
            testament to time and resilience. The leaves shine like silver as
            they catch the sunlight. The contrast is fascinating—the peaceful
            expanse of the sea meeting the earthy steadfastness of the olive
            grove. It gives you a feeling that the nature had woven together two
            distinct stories, uniting them in this breathtaking panorama. From
            this elevated perspective you can witness the delicate balance of
            nature unfold in front of your eyes. At the moment, when the day
            transitions into night you can enjoy beautiful sunsets. As you stand
            at the main entrance of the villas, your view encompasses several
            rows of olive trees, with a distant mountain rising in front of you.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default PanoramicView;
