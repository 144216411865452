import { Box, Grid, Typography } from '@mui/material';
import image from '../../assets/images/experiences-map.svg'
import ExperienceBeachCard from '../../components/cards/experience-beach-card/experience-beach-card'
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useState, useEffect } from 'react';
import Actions from '../../store/actions';
import Api from '../../api';
import { createExperiencesObjectFromArray } from '../../assets/helpers/content-data';
import LoadingModal from '../../components/modals/loading-modal/loading-modal';
import ConfirmModal from '../../components/modals/confirm-modal/confirm-modal';
import PrimaryButton from '../../components/bottons/primary-button';
import ImageChanger from '../../components/image-changer/image-changer';
import { Config } from '../../api/config';
import ExperiencesItems from '../../components/experience-items/experiences-items';
import BeachCreator from '../../components/beach-creatot/beach-creator';
import { BulletStar } from '../../components/bullet-star/BulletStar';

interface ContentData {
    [key: string]: string;
}

function Experiences() {

    const dispatch = useDispatch();
    const houseSession = useSelector((state: any) => state.houseSession)
    const contentSession = useSelector((state: any) => state.contentSession)
    const userSession = useSelector((state: any) => state.userSession)
    const beachSession = useSelector((state: any) => state.beachSession)

    const [contentData, setContentData] = useState<ContentData | null>(null)

    const fileName = "Experiences"
    const [edit, setEdit] = useState(false)
    const [save, setSave] = useState(false)

    const inputChangeHandler = () => {
        return ({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (contentData !== null)
                setContentData((prev: ContentData | null) => ({
                    ...prev,
                    [name]: value,
                }));
        };
    };

    const handleEnableEditing = () => {
        setEdit(true)
    }

    const handleSaveChanges = () => {
        setEdit(false)
        setSave(false)
        SetExperiencesPage()
    }

    const SetExperiencesPage = async () => {
        dispatch(Actions.startLoadingContentSession())
        if (contentData !== null)
            await Api.setExperiencesPage(Object.values(contentData))
                .then(response => {
                    dispatch(Actions.setExperiencesContent(createExperiencesObjectFromArray(response.data)))
                    dispatch(Actions.endLoadingContentSession(""))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(Actions.endLoadingContentSession("ERROR"))
                    /*if(err.message === 'Network Error')
                      navigate('/error')*/
                })
    }

    useEffect(() => {
        if (contentData === null && contentSession.experiences !== null)
            setContentData(contentSession.experiences)
        console.log(contentSession.experiences)
    }, [contentSession])

    const [beachToEdit, setBeachToEdit] = useState(null)

    return (
        <>
            <LoadingModal open={contentSession.loading || houseSession.loading} />
            <ConfirmModal open={save} handelNo={() => { setSave(false) }} handleConfirm={handleSaveChanges} />
            {contentData !== null ?
                <>
                    {
                        userSession.data !== "" &&
                        <Grid sx={{ position: "fixed", top: "10px", right: "10px", zIndex: "9999", visibility: save ? "hidden" : "visible" }}>
                            {
                                !edit ?
                                    <PrimaryButton onClick={handleEnableEditing}>Enable editing</PrimaryButton>
                                    :
                                    <PrimaryButton onClick={() => { setSave(true) }}>Save changes</PrimaryButton>
                            }
                        </Grid>
                    }
                    <Grid className="accommodation-header" container sx={{ position: "relative", backgroundImage: `url(${Config.imageBaseURL}${contentData.headerImage})` }}>
                        <Grid container sx={{ textAlign: "center", display: "flex", justifyContent: "center", margin: "50px 0px", alignItems: "center" }}>
                            <Typography variant='h3' sx={{ fontWeight: "bold", color: "white" }}>
                                Experiences
                            </Typography>
                        </Grid>
                        {
                            edit &&
                            <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
                                <ImageChanger pageFileName={fileName} oldImageName={contentData.headerImage} name='headerImage' onChange={inputChangeHandler()} />
                            </div>
                        }
                    </Grid>

                    <Grid container sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} md={11} lg={9} xl={7} sx={{}}>
                            {
                                !edit ?
                                    <>
                                        <Typography variant='h3' sx={{ margin: "50px 20px" }}>
                                            {contentData.title1}
                                        </Typography>
                                        <Typography variant='body1' sx={{ margin: "50px 20px" }}>
                                            {contentData.text1}
                                        </Typography>
                                    </>
                                    :
                                    <div style={{ margin: "50px 0px" }}>
                                        <input className='admin-input' type='text' name="title1" value={contentData.title1} onChange={inputChangeHandler()}></input>
                                        <br />
                                        <textarea style={{ height: "300px" }} className='admin-textarea' name="text1" value={contentData.text1} onChange={inputChangeHandler()} />
                                    </div>
                            }
                        </Grid>
                    </Grid>

                    <Grid container sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} md={11} lg={9} xl={7} sx={{}}>
                            {
                                !edit ?
                                    <>
                                        <Typography variant='h3' sx={{ margin: "50px 20px" }}>
                                            {contentData.title2}
                                        </Typography>
                                    </>
                                    :
                                    <div style={{ margin: "50px 0px" }}>
                                        <input className='admin-input' type='text' name="title2" value={contentData.title2} onChange={inputChangeHandler()}></input>
                                    </div>
                            }
                        </Grid>
                        <Grid item xs={11} md={11} lg={9} xl={7} sx={{ marginBottom: "50px" }}>
                            <img src={image} className="expoeriences-image" />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ justifyContent: "center", backgroundColor: "rgba(130,139,178,0.1)" }}>
                        <Grid item xs={12} md={11} lg={9} xl={7} sx={{}}>
                            {
                                !edit ?
                                    <>
                                        <Typography variant='h3' sx={{ margin: "50px 20px" }}>
                                            {contentData.title3}
                                        </Typography>
                                    </>
                                    :
                                    <div style={{ margin: "50px 0px" }}>
                                        <input className='admin-input' type='text' name="title3" value={contentData.title3} onChange={inputChangeHandler()}></input>
                                    </div>
                            }
                        </Grid>
                        <Grid item xs={11} md={11} lg={9} xl={7} sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", marginBottom: "50px" }}>

                            {
                                beachSession.beaches.map((elem: { id: number, name: string, description: string, images: string }) => {
                                    return (
                                        <ExperienceBeachCard edit={edit} setBeachToEdit={setBeachToEdit} key={elem.name} id={elem.id} name={elem.name} images={elem.images !== "" ? elem.images.split(';') : []} description={elem.description} />
                                    )
                                })
                            }
                            {
                                beachToEdit !== null &&
                                <BeachCreator setBeachToEdit={setBeachToEdit} data={beachToEdit} />
                            }
                            {
                                edit && <BeachCreator />
                            }
                            
                        </Grid>
                    </Grid>

                    <Grid container sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} md={11} lg={9} xl={7} sx={{}}>
                            {
                                !edit ?
                                    <>
                                        <Typography variant='h3' sx={{ margin: "50px 20px" }}>
                                            {contentData.title4}
                                        </Typography>
                                    </>
                                    :
                                    <div style={{ margin: "50px 0px" }}>
                                        <input className='admin-input' type='text' name="title4" value={contentData.title4} onChange={inputChangeHandler()}></input>
                                    </div>
                            }
                        </Grid>
                    </Grid>

                    <Grid container sx={{ alignContent: "center", justifyContent: "center" }}>
                        <Grid item xs={10} md={8} xl={3} lg={3} sx={{ margin: { xs: "20px auto", lg: "0px 50px 0px 0px" } }}>
                            {
                                !edit ?
                                    <>
                                        <Typography variant='h4' sx={{ marginBottom: "50px" }}>
                                            {contentData.subTitle1}
                                        </Typography>
                                    
                                        {
                                            contentData.text2.split(";").map((text: string) => {
                                                return (
                                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                                                    <BulletStar/>
                                                    <Typography key={text} variant='body1'>
                                                        {text}
                                                    </Typography>
                                                    </div>
                                                )
                                            })}
                                    </>
                                    :
                                    <div style={{ margin: "50px 0px" }}>
                                        <input className='admin-input' type='text' name="subTitle1" value={contentData.subTitle1} onChange={inputChangeHandler()}></input>
                                        <ExperiencesItems name='text2' onChange={inputChangeHandler()} values={contentData.text2.split(";")} />
                                    </div>
                            }
                        </Grid>
                        <Grid item xs={10} md={8} xl={4} lg={5} sx={{ margin: { xs: "20px auto", lg: "50px 0px 60px 0px" }, justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                            <div style={{ backgroundImage: `url(${Config.imageBaseURL}${contentData.image1})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginBottom: "50px", width: "48%", aspectRatio: "3/4" }}>
                                {
                                    edit &&
                                    <ImageChanger pageFileName={fileName} oldImageName={contentData.image1} name='image1' onChange={inputChangeHandler()} />
                                }
                            </div>
                            <div style={{ backgroundImage: `url(${Config.imageBaseURL}${contentData.image2})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginTop: "50px", width: "48%", aspectRatio: "3/4" }}>
                                {
                                    edit &&
                                    <ImageChanger pageFileName={fileName} oldImageName={contentData.image2} name='image2' onChange={inputChangeHandler()} />
                                }
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ alignContent: "center", justifyContent: "center", backgroundColor: "rgba(130,139,178,0.1)" }}>
                        <Grid item xs={10} md={8} xl={4} lg={5} sx={{ margin: { xs: "20px auto", lg: "50px 0px 120px 0px" }, justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                            <div style={{ backgroundImage: `url(${Config.imageBaseURL}${contentData.image3})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginBottom: "50px", width: "48%", aspectRatio: "3/4" }}>
                                {
                                    edit &&
                                    <ImageChanger pageFileName={fileName} oldImageName={contentData.image3} name='image3' onChange={inputChangeHandler()} />
                                }
                            </div>
                            <div style={{ backgroundImage: `url(${Config.imageBaseURL}${contentData.image4})`, display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "cover", backgroundPosition: "50% 50%", marginTop: "50px", width: "48%", aspectRatio: "3/4" }}>
                                {
                                    edit &&
                                    <ImageChanger pageFileName={fileName} oldImageName={contentData.image4} name='image4' onChange={inputChangeHandler()} />
                                }
                            </div>
                        </Grid>
                        <Grid item xs={10} md={8} xl={3} lg={3} sx={{ margin: { xs: "20px auto", lg: "50px 0px 0px 50px" } }}>
                            {
                                !edit ?
                                    <>
                                        <Typography variant='h4' sx={{ marginBottom: "50px" }}>
                                            {contentData.subTitle2}
                                        </Typography>
                                        {
                                            contentData.text3.split(";").map((text: string) => {
                                                return (
                                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                                                    <BulletStar/>
                                                    <Typography key={text} variant='body1'>
                                                        {text}
                                                    </Typography>
                                                    </div>
                                                )
                                            })}
                                    </>
                                    :
                                    <div style={{ margin: "50px 0px" }}>
                                        <input className='admin-input' type='text' name="subTitle2" value={contentData.subTitle2} onChange={inputChangeHandler()}></input>
                                        <ExperiencesItems name='text3' onChange={inputChangeHandler()} values={contentData.text3.split(";")} />
                                    </div>
                            }
                        </Grid>
                    </Grid>

                    <Grid container sx={{ justifyContent: "center", marginTop: "50px" }}>
                        <Grid item xs={12} md={6} lg={9} xl={7} sx={{ display: "flex", flexWrap: "wrap" }}>
                            <Grid item xs={12} md={6} sx={{}}>
                                {
                                    !edit ?
                                        <>
                                            <Typography variant='h4' sx={{ margin: "30px 20px 20px 20px" }}>
                                                {contentData.subTitle3}
                                            </Typography>
                                            {
                                                contentData.text4.split(";").map((text: string) => {
                                                    return (
                                                        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                                                    <BulletStar/>
                                                        <Typography key={text} variant='body1'>
                                                            {text}
                                                        </Typography>
                                                        </div>
                                                    )
                                                })}
                                        </>
                                        :
                                        <div style={{ margin: "50px 0px" }}>
                                            <input className='admin-input' type='text' name="subTitle3" value={contentData.subTitle3} onChange={inputChangeHandler()}></input>
                                            <ExperiencesItems name='text4' onChange={inputChangeHandler()} values={contentData.text4.split(";")} />
                                        </div>
                                }
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ backgroundColor: "rgba(130,139,178,0.1)" }}>
                                {
                                    !edit ?
                                        <>
                                            <Typography variant='h4' sx={{ margin: "30px 20px 20px 20px" }}>
                                                {contentData.subTitle4}
                                            </Typography>
                                            {
                                                contentData.text5.split(";").map((text: string) => {
                                                    return (
                                                        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                                                    <BulletStar/>
                                                        <Typography key={text} variant='body1'>
                                                            {text}
                                                        </Typography>
                                                        </div>
                                                    )
                                                })}
                                        </>
                                        :
                                        <div style={{ margin: "50px 0px" }}>
                                            <input className='admin-input' type='text' name="subTitle4" value={contentData.subTitle4} onChange={inputChangeHandler()}></input>
                                            <ExperiencesItems name='text5' onChange={inputChangeHandler()} values={contentData.text5.split(";")} />
                                        </div>
                                }
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ backgroundColor: "rgba(130,139,178,0.1)" }}>
                                {
                                    !edit ?
                                        <>
                                            <Typography variant='h4' sx={{ margin: "30px 20px 20px 20px" }}>
                                                {contentData.subTitle5}
                                            </Typography>
                                            {
                                                contentData.text6.split(";").map((text: string) => {
                                                    return (
                                                        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                                                    <BulletStar/>
                                                        <Typography key={text} variant='body1'>
                                                            {text}
                                                        </Typography>
                                                        </div>
                                                    )
                                                })}
                                        </>
                                        :
                                        <div style={{ margin: "50px 0px" }}>
                                            <input className='admin-input' type='text' name="subTitle5" value={contentData.subTitle5} onChange={inputChangeHandler()}></input>
                                            <ExperiencesItems name='text6' onChange={inputChangeHandler()} values={contentData.text6.split(";")} />
                                        </div>
                                }
                            </Grid>
                            <Grid item xs={12} md={6} sx={{}}>
                                {
                                    !edit ?
                                        <>
                                            <Typography variant='h4' sx={{ margin: "30px 20px 20px 20px" }}>
                                                {contentData.subTitle6}
                                            </Typography>
                                            {
                                                contentData.text7.split(";").map((text: string) => {
                                                    return (
                                                        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                                                    <BulletStar/>
                                                        <Typography key={text} variant='body1'>
                                                            {text}
                                                        </Typography>
                                                        </div>
                                                    )
                                                })}
                                        </>
                                        :
                                        <div style={{ margin: "50px 0px" }}>
                                            <input className='admin-input' type='text' name="subTitle6" value={contentData.subTitle6} onChange={inputChangeHandler()}></input>
                                            <ExperiencesItems name='text7' onChange={inputChangeHandler()} values={contentData.text7.split(";")} />
                                        </div>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} md={11} lg={9} xl={7} sx={{}}>
                            {
                                !edit ?
                                    <>
                                        <Typography variant='h4' sx={{ margin: "30px 20px 0px 20px" }}>
                                         {contentData.subTitle7} 
                                        </Typography>
                                        <Typography variant='body1' sx={{ margin: "50px 20px" }}>
                                          {contentData.text8} 
                                        </Typography>
                                    </>
                                    :
                                    <div style={{ margin: "50px 0px" }}>
                                        <input className='admin-input' type='text' name="subTitle7" value={contentData.subTitle7} onChange={inputChangeHandler()}></input>
                                        <br />
                                        <textarea style={{ height: "300px" }} className='admin-textarea' name="text8" value={contentData.text8} onChange={inputChangeHandler()} />
                                    </div>
                            }
                        </Grid>
                    </Grid>

                    <Grid container sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} md={11} lg={9} xl={7} sx={{}}>
                            {
                                !edit ?
                                    <>
                                        <Typography variant='h4' sx={{ margin: "30px 20px 0px 20px" }}>
                                            {contentData.subTitle8}
                                        </Typography>
                                        <Typography variant='body1' sx={{ margin: "50px 20px" }}>
                                            {contentData.text9}
                                        </Typography> 
                                    </>
                                    :
                                    <div style={{ margin: "50px 0px" }}>
                                        <input className='admin-input' type='text' name="subTitle8" value={contentData.subTitle8} onChange={inputChangeHandler()}></input>
                                        <br />
                                        <textarea style={{ height: "300px" }} className='admin-textarea' name="text9" value={contentData.text9} onChange={inputChangeHandler()} />
                                    </div>
                            }
                        </Grid>
                    </Grid>
                </>
                :
                <>
                    <Grid container sx={{ height: "100vh", backgroundColor: "white" }}></Grid>
                </>
            }
        </>
    )
}

export default Experiences;