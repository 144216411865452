import {
  Grid,
  Typography,
  CardActionArea,
  CardActions,
  Skeleton,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import SecondaryButton from "../../bottons/secondary-button";
import { House } from "../../../models/House";
import { Feature } from "../../../models/Feature";
import { Config } from "../../../api/config";
import { useMemo } from "react";
import {
  RoomRaccoonVillaLemonUrl,
  RoomRaccoonVillaOliveUrl,
  RoomRaccoonVillaPineUrl,
} from "../../../models/constants/Links";
import { useNavigate } from "react-router-dom";

interface Props {
  data: House;
  loading: boolean;
}

function HouseCard({ data, loading }: Props) {
  const houseName = useMemo(() => {
    if (data.houseTranslations && data.houseTranslations[0])
      return data.houseTranslations[0].name;
    return "";
  }, []);

  const navigate = useNavigate();

  const bookNowUrl = useMemo(() => {
    switch (houseName) {
      case "Villa Lemon": {
        return RoomRaccoonVillaLemonUrl;
      }
      case "Villa Olive": {
        return RoomRaccoonVillaOliveUrl;
      }
      case "Villa Pine": {
        return RoomRaccoonVillaPineUrl;
      }
      default: {
        return "";
      }
    }
  }, []);

  const image =
    data.images && data.images.length > 0
      ? Config.houseImageBaseURL + data.images[0].bigImageName
      : undefined;
  return (
    <Grid
      item
      xs={11}
      md={4}
      lg={3}
      xl={2}
      sx={{ margin: "20px 20px", borderRadius: "0px" }}
    >
      <Card
        className="room-card"
        sx={{
          width: "98%",
          margin: "0 auto",
          border: "none",
          boxShadow: "none",
          borderRadius: "0px",
        }}
        onClick={() => {
          navigate("/details/" + houseName);
        }}
      >
        <CardActionArea sx={{ borderRadius: "0px" }}>
          {loading ? (
            <Skeleton
              animation="wave"
              sx={{
                borderRadius: "0px",
                width: "100%",
                height: "300px",
                margin: "0px",
                padding: "0px",
                transform: "scale(1)",
              }}
            />
          ) : (
            <CardMedia
              component="img"
              height="300"
              image={image}
              alt="green iguana"
              sx={{ borderRadius: "0px" }}
            />
          )}

          <CardContent
            sx={{ padding: "20px 0px 20px 0px", borderRadius: "0px" }}
          >
            {loading ? (
              <>
                <Skeleton
                  animation="wave"
                  height={20}
                  sx={{ width: "100%", margin: "20px 0px" }}
                />
                <Skeleton
                  animation="wave"
                  height={15}
                  sx={{ width: "80%", margin: "10px 0px" }}
                />
                <Skeleton
                  animation="wave"
                  height={15}
                  sx={{ width: "80%", margin: "10px 0px" }}
                />
                <Skeleton
                  animation="wave"
                  height={15}
                  sx={{ width: "80%", margin: "10px 0px" }}
                />
              </>
            ) : (
              <>
                <Typography gutterBottom variant="h5" component="div">
                  {data.houseTranslations &&
                    data.houseTranslations.length > 0 &&
                    data.houseTranslations[0].name}
                </Typography>

                {data.features &&
                  data.features.length > 0 &&
                  data.features.map((feature: Feature) => {
                    return (
                      <Typography
                        key={feature.id}
                        sx={{ margin: "15px 0px" }}
                        variant="body2"
                        color="text.secondary"
                      >
                        {feature.name}
                      </Typography>
                    );
                  })}
              </>
            )}
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ padding: "0px" }}>
          <a
            href={bookNowUrl}
            style={{ width: "100%" }}
            target="_blank"
            rel="noopener noreferrer"
            className="app-bar-link"
          >
            <SecondaryButton>Book now</SecondaryButton>
          </a>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default HouseCard;
