import { Grid, Typography, TextField, Box, Link } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { EmailOutlined } from "@mui/icons-material";
import SecondaryButton from "../../components/bottons/secondary-button";
import Maps from "../../components/map/map";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../store/actions";
import { createContactObjectFromArray } from "../../assets/helpers/content-data";
import Api from "../../api";
import { ChangeEvent, useState } from "react";
import PrimaryButton from "../../components/bottons/primary-button";
import LoadingModal from "../../components/modals/loading-modal/loading-modal";
import ConfirmModal from "../../components/modals/confirm-modal/confirm-modal";
import { useEffect } from "react";
import NotificationModal from "../../components/modals/notification-modal/notification-modal";
import { Config } from "../../api/config";
import ImageChanger from "../../components/image-changer/image-changer";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TikTokIcon from "../../assets/images/tiktoktransparent.png";
import TikTokSvg from "../../assets/images/tiktoksvg.svg";
import { TikTokUrl } from "../../models/constants/Links";

interface ContentData {
  [key: string]: string;
}

interface FormData {
  body: { value: string; error: string };
  email: { value: string; error: string };
  fullName: { value: string; error: string };
  subject: { value: string; error: string };
}

function Contact() {
  const dispatch = useDispatch();
  const contentSession = useSelector((state: any) => state.contentSession);
  const userSession = useSelector((state: any) => state.userSession);
  const [errorText, setErrorText] = useState("");
  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);

  const fileName = "Contact";

  const [contentData, setContentData] = useState<ContentData | null>(null);

  const [formData, setFormData] = useState<FormData>({
    body: { value: "", error: "" },
    email: { value: "", error: "" },
    fullName: { value: "", error: "" },
    subject: { value: "", error: "" },
  });

  const handleEnableEditing = () => {
    setEdit(true);
  };

  const handleSaveChanges = () => {
    setEdit(false);
    setSave(false);
    SetAccommodationPage();
  };

  const SetAccommodationPage = async () => {
    dispatch(Actions.startLoadingContentSession());
    if (contentData !== null)
      await Api.setContactPage(Object.values(contentData))
        .then((response) => {
          dispatch(
            Actions.setContactContent(
              createContactObjectFromArray(response.data)
            )
          );
          dispatch(Actions.endLoadingContentSession(""));
        })
        .catch((err) => {
          console.log(err);
          dispatch(Actions.endLoadingContentSession("ERROR"));
          /*if(err.message === 'Network Error')
                      navigate('/error')*/
        });
  };

  const inputChangeHandler = () => {
    return ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (contentData !== null)
        setContentData((prev: ContentData | null) => ({
          ...prev,
          [name]: value,
        }));
    };
  };

  const formInputChangeHandler = () => {
    return ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (contentData !== null)
        setFormData((prev: FormData) => ({
          ...prev,
          [name]: {
            value: value,
            error:
              value !== ""
                ? name !== "email"
                  ? validateInputWithDigits(value)
                  : validateEmail(value)
                : "Required field.",
          },
        }));
    };
  };

  const validateInputWithDigits = (value: string) => {
    var mailformat =
      /[A-Z]|[0-9]|[' ']|[',']|['.']|['ć']|['č']|['š']|['đ']|['ž']|['Ć']|['Č']|['Š']|['Đ']|['Ž']/gi;
    if (
      value !== "" &&
      value.match(mailformat) &&
      value?.length === value.match(mailformat)?.length
    ) {
      return "";
    }
    return "Incorrect entry.";
  };

  function validateEmail(email: string) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return "";
    }
    return "Incorrect entry.";
  }

  const [successfully, setSuccessfully] = useState<boolean | null>(null);

  const handleSendForm = async () => {
    if (
      formData.body.error === "" &&
      formData.email.error === "" &&
      formData.subject.error === "" &&
      formData.fullName.error === "" &&
      formData.body.value !== "" &&
      formData.email.value !== "" &&
      formData.subject.value !== "" &&
      formData.fullName.value !== ""
    ) {
      if (contentData !== null) {
        dispatch(Actions.startLoadingContentSession());
        await Api.sendContactForm({
          email: formData.email.value,
          subject: formData.subject.value,
          body: formData.body.value,
          fullName: formData.fullName.value,
        })
          .then((response) => {
            setFormData({
              email: { value: "", error: "" },
              subject: { value: "", error: "" },
              body: { value: "", error: "" },
              fullName: { value: "", error: "" },
            });
            setSuccessfully(true);
            dispatch(Actions.endLoadingContentSession(""));
          })
          .catch((err) => {
            setSuccessfully(false);
            dispatch(Actions.endLoadingContentSession("ERROR"));
          });
      }
    }
  };

  useEffect(() => {
    if (contentData === null && contentSession.contact !== null) {
      console.log(contentSession.contact);
      setContentData(contentSession.contact);
    }
  }, [contentSession]);
  return (
    <>
      <NotificationModal
        message="Sent successfully!"
        open={successfully !== null && successfully}
        handleConfirm={() => {
          setSuccessfully(null);
        }}
      />
      <NotificationModal
        message="Error sending!"
        open={successfully !== null && !successfully}
        handleConfirm={() => {
          setSuccessfully(null);
        }}
      />
      <LoadingModal open={contentSession.loading || userSession.loading} />
      <ConfirmModal
        open={save}
        handelNo={() => {
          setSave(false);
        }}
        handleConfirm={handleSaveChanges}
      />
      {contentData !== null ? (
        <>
          {userSession.data !== "" && (
            <Grid
              sx={{
                position: "fixed",
                top: "10px",
                right: "10px",
                zIndex: "9999",
                visibility: save ? "hidden" : "visible",
              }}
            >
              {!edit ? (
                <PrimaryButton onClick={handleEnableEditing}>
                  Enable editing
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    setSave(true);
                  }}
                >
                  Save changes
                </PrimaryButton>
              )}
            </Grid>
          )}
          <Grid
            className="contact-header"
            container
            sx={{
              position: "relative",
              backgroundImage: `url(${Config.imageBaseURL}${contentData.headerImage})`,
            }}
          >
            <Grid
              container
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                margin: "50px 0px",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                Get In Touch
              </Typography>
            </Grid>
            {edit && (
              <div
                style={{ position: "absolute", bottom: "10px", right: "10px" }}
              >
                <ImageChanger
                  pageFileName={fileName}
                  oldImageName={contentData.headerImage}
                  name="headerImage"
                  onChange={inputChangeHandler()}
                />
              </div>
            )}
          </Grid>

          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={11} lg={9} xl={7} sx={{}}>
              <Typography variant="h3" sx={{ margin: "50px 20px" }}>
                Welcome
              </Typography>
              {contentData !== null && (
                <>
                  {!edit ? (
                    <Typography variant="body1" sx={{ margin: "50px 20px" }}>
                      {contentData.text1}
                    </Typography>
                  ) : (
                    <textarea
                      style={{ height: "200px", marginBottom: "20px" }}
                      className="admin-textarea"
                      name="text1"
                      value={contentData.text1}
                      onChange={inputChangeHandler()}
                    ></textarea>
                  )}
                </>
              )}
            </Grid>
          </Grid>

          <Grid container sx={{ justifyContent: "center" }}>
            <Grid
              item
              xs={11}
              md={11}
              lg={9}
              xl={7}
              sx={{ height: "400px", marginTop: "50px" }}
            >
              <a
                style={{ textDecoration: "none" }}
                href={`https://www.google.com/maps/place/40%C2%B012'46.1%22N+23%C2%B041'11.9%22E/@40.2127928,23.6840655,17z/data=!3m1!4b1!4m4!3m3!8m2!3d40.2127928!4d23.6866404?hl=en-NL&entry=ttu`}
                target="_blank"
              >
                <Maps />
              </a>
            </Grid>
          </Grid>

          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={11} lg={9} xl={7} sx={{}}>
              <Typography variant="h3" sx={{ margin: "50px 20px" }}>
                Get in Touch
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ justifyContent: "center", marginBottom: "50px" }}
          >
            <Grid item xs={12} md={8} lg={6} xl={5} sx={{}}>
              <Grid container id="contact-form">
                <Grid item xs={12} sx={{ padding: "20px" }}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Your message"
                    multiline
                    maxRows={6}
                    rows={6}
                    fullWidth
                    value={formData.body.value}
                    name="body"
                    onChange={formInputChangeHandler()}
                    sx={{ borderRadius: "0px" }}
                    error={formData.body.error !== ""}
                    helperText={formData.body.error}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: "20px" }}>
                  <TextField
                    value={formData.fullName.value}
                    error={formData.fullName.error !== ""}
                    helperText={formData.fullName.error}
                    name="fullName"
                    onChange={formInputChangeHandler()}
                    fullWidth
                    id="outlined-basic"
                    label="Full name"
                    variant="outlined"
                    sx={{}}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: "20px" }}>
                  <TextField
                    value={formData.email.value}
                    error={formData.email.error !== ""}
                    helperText={formData.email.error}
                    name="email"
                    onChange={formInputChangeHandler()}
                    fullWidth
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    sx={{}}
                  />
                </Grid>
                <Grid item xs={12} sx={{ padding: "20px" }}>
                  <TextField
                    value={formData.subject.value}
                    error={formData.subject.error !== ""}
                    helperText={formData.subject.error}
                    name="subject"
                    onChange={formInputChangeHandler()}
                    fullWidth
                    id="outlined-basic"
                    label="Subject"
                    variant="outlined"
                    sx={{}}
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: "20px" }}>
                  <SecondaryButton onClick={handleSendForm}>
                    Send
                  </SecondaryButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} lg={3} xl={2} sx={{}}>
              <Box sx={{ marginTop: "10px" }}>
                <Box
                  sx={{
                    padding: "12px 36px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <HomeOutlinedIcon
                    fontSize="large"
                    sx={{ color: "#8f9195", marginRight: "10px" }}
                  />{" "}
                  Nikiti, Greece
                </Box>
                {!edit ? (
                  <>
                    <Box
                      sx={{
                        padding: "12px 36px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PhoneAndroidIcon
                        fontSize="large"
                        sx={{ color: "#8f9195", marginRight: "10px" }}
                      />
                      {contentData.phone}
                    </Box>
                    <Box
                      sx={{
                        padding: "12px 36px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <EmailOutlined
                        fontSize="large"
                        sx={{ color: "#8f9195", marginRight: "10px" }}
                      />
                      {contentData.email}
                    </Box>
                    <a
                      href={contentData.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="app-bar-link"
                    >
                      <Box
                        sx={{
                          padding: "12px 36px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FacebookIcon
                          fontSize="large"
                          sx={{ color: "#8f9195", marginRight: "10px" }}
                        />
                        <Typography
                          sx={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            wordWrap: "break-word",
                          }}
                        >
                          {contentData.facebook}
                        </Typography>
                      </Box>
                    </a>
                    <a
                      href={contentData.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="app-bar-link"
                    >
                      <Box
                        sx={{
                          padding: "12px 36px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <InstagramIcon
                          fontSize="large"
                          sx={{ color: "#8f9195", marginRight: "10px" }}
                        />
                        <Typography
                          sx={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            wordWrap: "break-word",
                          }}
                        >
                          {contentData.instagram}
                        </Typography>
                      </Box>
                    </a>
                    <a
                      href={TikTokUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="app-bar-link"
                    >
                      <Box
                        sx={{
                          padding: "12px 36px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            height: "35px",
                            width: "48px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={TikTokSvg}
                            alt=""
                            color="#8f9195"
                            style={{ height: "90%", width: "90%" }}
                          />
                        </div>
                        <Typography
                          sx={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            wordWrap: "break-word",
                          }}
                        >
                          {TikTokUrl}
                        </Typography>
                      </Box>
                    </a>
                  </>
                ) : (
                  <>
                    <input
                      className="admin-input"
                      type="text"
                      name="phone"
                      value={contentData.phone}
                      onChange={inputChangeHandler()}
                    ></input>
                    <input
                      className="admin-input"
                      type="text"
                      name="email"
                      value={contentData.email}
                      onChange={inputChangeHandler()}
                    ></input>
                    <input
                      className="admin-input"
                      type="text"
                      name="facebook"
                      value={contentData.facebook}
                      onChange={inputChangeHandler()}
                    ></input>
                    <input
                      className="admin-input"
                      type="text"
                      name="instagram"
                      value={contentData.instagram}
                      onChange={inputChangeHandler()}
                    ></input>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            sx={{ height: "100vh", backgroundColor: "white" }}
          ></Grid>
        </>
      )}
    </>
  );
}

export default Contact;
